import {
  Switch,
  Match,
  Accessor,
  For,
  Show,
  createSignal,
  Setter,
} from "solid-js";
import {
  AnnotationTypes,
  EntityTypes,
  MachineListCompressed,
  ModelsListCompressed,
  RuntimeTypes,
} from "../../../helpers/types";
import { ChevronDoubleRight } from "../../../components/Icons";
import DatasetCard from "./DatasetCard";
import { _CreateSource, _CreateTask } from "../../../helpers/mutations";
import configtemplate from "../../../utils/configtemplate.json";

interface AddNewProps {
  type: Accessor<EntityTypes>;
  setType: Setter<EntityTypes>;
  addFunction: (input: object) => void;
  getMachinesList: Accessor<MachineListCompressed | undefined>;
  getModelsList: Accessor<ModelsListCompressed | undefined>;
}

export function AddObject(props: AddNewProps) {
  const [showErrorMessage, setShowErrorMessage] = createSignal(false);

  //For adding task
  var descriptionInput!: HTMLTextAreaElement;
  var machineSelector!: HTMLSelectElement;
  var modelSelector!: HTMLSelectElement;
  var selectedModel: any;
  const [datasetsUsedByModel, setDatasetsUsedByModel] =
    createSignal(selectedModel);
  const [selectedModelName, setSelectedModelName] = createSignal<string>();

  const createTaskHandler = () => {
    var input = {};

    if (machineSelector.selectedIndex !== 0) {
      input = {
        MachineID: machineSelector.value,
      };
    }
    if (modelSelector.selectedIndex !== 0) {
      input = { ...input, ModelID: modelSelector.value };
    }

    input = {
      ...input,
      description: { description: descriptionInput.value },
      imageKey: null, //Change to prefered value
    };

    props.addFunction({ input });
    props.setType(EntityTypes.default);
  };

  //For adding machine
  var machineNameInput!: HTMLInputElement;
  var configInput!: HTMLTextAreaElement;

  const [correctFormat, setCorrectFormat] = createSignal<boolean>(true);

  const formatConfigData = (config: string) => {
    if (config !== "") {
      const jsonObject = JSON.parse(config);
      return JSON.stringify(jsonObject, null, 2);
    }
    if (config === "") {
      setCorrectFormat(false);
    }
  };

  const configInputHandler = () => {
    try {
      formatConfigData(configInput.value);
      setCorrectFormat(true);
    } catch (error) {
      setCorrectFormat(false);
    }
  };

  const createMachineHandler = () => {
    if (machineNameInput.value === "") {
      setShowErrorMessage(true);
      window.location.href = "#add";
    } else if (machineNameInput.value !== "" && correctFormat()) {
      setShowErrorMessage(false);
      var input: any;
      input = { name: machineNameInput.value };
      if (configInput.value !== "" && correctFormat()) {
        input = { ...input, config: configInput.value };
      }
      props.addFunction({ input });
      props.setType(EntityTypes.default);
    }
  };

  //For adding source
  var sourceNameInput!: HTMLInputElement;

  //For adding dataset
  var datasetNameInput!: HTMLInputElement;
  var annotationSelector!: HTMLSelectElement;

  const createDatasetHandler = () => {
    if (datasetNameInput.value === "") {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
      const input = {
        name: datasetNameInput.value,
        annotationType: annotationSelector.value,
      };

      props.addFunction({ input });
      props.setType(EntityTypes.default);
    }
  };

  //For adding model
  var modelNameInput!: HTMLInputElement;
  var runtimeSelector!: HTMLSelectElement;

  return (
    <div id="add">
      <div class="mx-auto my-10 w-10/12 bg-white shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm">
        <h2 class="px-4 pb-2 pt-4 text-xl font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 bg-gray-50 bg-opacity-75 ">
          Add new {props.type}
        </h2>
        <Switch>
          <Match when={props.type() === EntityTypes.task}>
            <div class="flex flex-row p-5">
              <div class="w-1/2">
                <label
                  for="taskDescription"
                  class="block font-medium text-gray-700 px-5 mt-5 mb-1"
                >
                  Description*
                </label>
                <textarea
                  class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
                  id="taskDescription"
                  ref={descriptionInput}
                  required
                  placeholder="Required - A short description of task"
                />
                <Show when={showErrorMessage()}>
                  <p class="ml-5 text-sm font-medium text-red-700">
                    *Description is required
                  </p>
                </Show>
                {/* TODO: Create upload image feature/As it is now you have to use Apollo explorer to choose image for task */}
                <label
                  for="imageKey"
                  class="block font-medium text-gray-700 px-5 mt-5 mb-1"
                >
                  Image
                </label>
                <input
                  type="text"
                  id="imageKey"
                  placeholder="Default"
                  disabled
                  class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
                />
              </div>
              <div class="w-1/2">
                <div class="mt-5 mb-10">
                  <div class="flex flex-row mb-2">
                    <h5 class="block text-medium font-medium text-gray-700">
                      Add machine
                    </h5>
                    <ChevronDoubleRight />
                  </div>
                  <select
                    class="block w-10/12 appearance-none rounded-md border border-gray-300 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-500 sm:text-sm"
                    id="currentMachine"
                    ref={machineSelector}
                  >
                    <option>Not selected</option>
                    <For each={props.getMachinesList()}>
                      {(machine) => (
                        <option value={machine.id}>{machine.name}</option>
                      )}
                    </For>
                  </select>
                </div>
                <div class="flex flex-row mb-2">
                  <h5 class="block text-medium font-medium text-gray-700 self-start">
                    Add model
                  </h5>
                  <ChevronDoubleRight />
                </div>
                <select
                  class="block w-10/12 appearance-none rounded-md border border-gray-300 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-500 sm:text-sm"
                  id="currentModel"
                  ref={modelSelector}
                  onChange={(event: any) => {
                    selectedModel = props
                      .getModelsList()
                      ?.find((model) => model.id === event?.target.value);
                    setDatasetsUsedByModel(selectedModel);
                    setSelectedModelName(selectedModel?.name);
                  }}
                >
                  <option>Not selected</option>
                  <For each={props.getModelsList()}>
                    {(model) => <option value={model.id}>{model.name}</option>}
                  </For>
                </select>
              </div>
            </div>
            <Show when={datasetsUsedByModel() !== undefined}>
              <div class="m-5">
                <div class="flex flex-row">
                  <h5 class="block ml-5 mr-3 my-auto text-medium font-medium text-gray-700">
                    Datasets used by {selectedModelName()}
                  </h5>
                  <ChevronDoubleRight />
                </div>
                <div class="grid grid-cols-2">
                  <For each={datasetsUsedByModel()?.datasets.items}>
                    {(dataset) => (
                      <DatasetCard
                        name={dataset.Dataset.name}
                        id={dataset.Dataset.id}
                        updatedAt={dataset.Dataset.updatedAt}
                        images={dataset.Dataset.images.items.length}
                      ></DatasetCard>
                    )}
                  </For>
                </div>
                <Show when={datasetsUsedByModel()?.datasets.items.length === 0}>
                  <div class="block text-sm my-4 mx-5">
                    <p class=" font-medium text-gray-500">
                      No current datasets used by model
                    </p>
                  </div>
                </Show>
              </div>{" "}
            </Show>
            <div class="flex flex-row justify-center p-5">
              <a
                class="nl-button nl-button--xs m-1 cursor-pointer"
                onClick={() => {
                  if (descriptionInput.value === "") {
                    setShowErrorMessage(true);
                  } else {
                    setShowErrorMessage(false);
                    createTaskHandler();
                  }
                }}
              >
                Create
              </a>
              <a
                class="nl-button nl-button--xs m-1 text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
                onClick={() => {
                  props.setType(EntityTypes.default);
                  window.location.href = "#taskoverview";
                }}
              >
                Cancel
              </a>
            </div>
          </Match>

          <Match when={props.type() === EntityTypes.machine}>
            <div class="flex flex-col p-5">
              <div class="w-1/2 mb-10">
                <label
                  for="machineName"
                  class="block font-medium text-gray-700 px-5 mt-5 mb-1"
                >
                  Name*
                </label>
                <input
                  class="block w-8/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
                  id="machineName"
                  type="text"
                  ref={machineNameInput}
                />
                <Show when={showErrorMessage()}>
                  <p class="ml-5 text-sm font-medium text-red-700">
                    *Name is required
                  </p>
                </Show>
              </div>
              <label for="config" class="mx-5 font-medium text-gray-700">
                Configuration
              </label>
              <textarea
                class="block rounded-md h-screen  mx-5 mt-2 p-5 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm"
                classList={{
                  "border-2 focus:border-green-400 focus:ring-green-400 border-green-400":
                    correctFormat(),
                  "border-2 focus:border-red-400 focus:ring-red-400 border-red-400":
                    !correctFormat() && correctFormat() !== undefined,
                }}
                onInput={() => {
                  configInputHandler();
                }}
                value={JSON.stringify(configtemplate, null, 2)}
                ref={configInput}
              />
            </div>
            <div class="flex flex-row justify-center p-5">
              <a
                class="nl-button nl-button--xs m-1 cursor-pointer"
                onClick={() => {
                  createMachineHandler();
                }}
              >
                Create
              </a>
              <a
                class="nl-button nl-button--xs m-1 text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
                onClick={() => {
                  props.setType(EntityTypes.default);
                  window.location.href = "#machineoverview";
                }}
              >
                Cancel
              </a>
            </div>
          </Match>

          <Match when={props.type() === EntityTypes.source}>
            <div class="w-1/2 p-5">
              <label
                for="sourceName"
                class="block font-medium text-gray-700 px-5 mt-5 mb-1"
              >
                Name*
              </label>
              <input
                type="text"
                id="sourceName"
                ref={sourceNameInput}
                class="block w-8/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
              />
              <Show when={showErrorMessage()}>
                <p class="ml-5 text-sm font-medium text-red-700">
                  *Name is required
                </p>
              </Show>
            </div>
            <div class="flex flex-row justify-center p-5">
              <a
                class="nl-button nl-button--xs m-1 cursor-pointer"
                onClick={() => {
                  if (sourceNameInput.value === "") {
                    setShowErrorMessage(true);
                  } else {
                    setShowErrorMessage(false);
                    const input = { name: sourceNameInput.value };
                    props.addFunction({ input });
                    props.setType(EntityTypes.default);
                  }
                }}
              >
                Create
              </a>
              <a
                class="nl-button nl-button--xs m-1 text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
                onClick={() => {
                  props.setType(EntityTypes.default);
                  window.location.href = "#sourcesoverview";
                }}
              >
                Cancel
              </a>
            </div>
          </Match>

          <Match when={props.type() === EntityTypes.dataset}>
            <div class="w-1/2 p-5">
              <label
                for="datasetName"
                class="block font-medium text-gray-700 px-5 mt-5 mb-1"
              >
                Name*
              </label>
              <input
                type="text"
                id="datasetName"
                ref={datasetNameInput}
                class="block w-8/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
              />
              <Show when={showErrorMessage()}>
                <p class="ml-5 text-sm font-medium text-red-700">
                  *Name is required
                </p>
              </Show>
              <label
                for="annotationType"
                class="block font-medium text-gray-700 px-5 mt-7 mb-1"
              >
                AnnotationType*
              </label>
              <select
                class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-500 sm:text-sm"
                id="annotationType"
                ref={annotationSelector}
              >
                <For each={Object.values(AnnotationTypes)}>
                  {(annotationType) => (
                    <option value={annotationType}>{annotationType}</option>
                  )}
                </For>
              </select>
            </div>
            <div class="flex flex-row justify-center p-5">
              <a
                class="nl-button nl-button--xs m-1 cursor-pointer"
                onClick={() => {
                  createDatasetHandler();
                }}
              >
                Create
              </a>
              <a
                class="nl-button nl-button--xs m-1 text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
                onClick={() => {
                  props.setType(EntityTypes.default);
                  window.location.href = "#datasetoverview";
                }}
              >
                Cancel
              </a>
            </div>
          </Match>

          <Match when={props.type() === EntityTypes.model}>
            <div class="w-1/2 p-5">
              <label
                for="datasetName"
                class="block font-medium text-gray-700 px-5 mt-5 mb-1"
              >
                Name*
              </label>
              <input
                class="block w-8/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
                id="datasetName"
                type="text"
                ref={modelNameInput}
              />
              <Show when={showErrorMessage()}>
                <p class="ml-5 text-sm font-medium text-red-700">
                  *Name is required
                </p>
              </Show>
              <label
                for="runtimeTypes"
                class="block font-medium text-gray-700 px-5 mt-5 mb-1"
              >
                Runtime*
              </label>
              <select
                class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
                id="runtimeTypes"
                ref={runtimeSelector}
              >
                <For each={Object.values(RuntimeTypes)}>
                  {(runtime) => <option value={runtime}>{runtime}</option>}
                </For>
              </select>
            </div>
            <div class="flex flex-row justify-center p-5">
              <a
                class="nl-button nl-button--xs m-1 cursor-pointer"
                onClick={() => {
                  if (modelNameInput.value === "") {
                    setShowErrorMessage(true);
                  } else {
                    setShowErrorMessage(false);
                    const input = {
                      name: modelNameInput.value,
                      runtime: runtimeSelector.value,
                    };
                    props.addFunction({ input });
                    props.setType(EntityTypes.default);
                  }
                }}
              >
                Create
              </a>
              <a
                class="nl-button nl-button--xs m-1 text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
                onClick={() => {
                  props.setType(EntityTypes.default);
                  window.location.href = "#modeloverview";
                }}
              >
                Cancel
              </a>
            </div>
          </Match>
        </Switch>
      </div>
    </div>
  );
}
