import { GraphQLClientQuery } from "@solid-primitives/graphql";
import {
  DatasetList,
  DatasetListCompressed,
  EntityTypes,
  ModelsList,
  ModelsListCompressed,
  SingleModelType,
} from "../../../helpers/types";
import {
  Accessor,
  For,
  Match,
  Setter,
  Show,
  Switch,
  createEffect,
  createSignal,
} from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import DeleteConfirmationBox from "../../../components/DeleteConfirmationBox";
import { CopyIcon, SpinnerIcon } from "../../../components/Icons";
import { createStore } from "solid-js/store";
import { _DeleteModel } from "../../../helpers/mutations";
import ConfigModelOverview from "./ConfigModelOverview";

const _emptyModel: SingleModelType = {
  id: "",
  name: "",
  createdAt: "",
  updatedAt: "",
  runtime: "",
  datasets: {
    items: [],
  },
};

interface ModelsListProps {
  getModelsList: Accessor<ModelsListCompressed | undefined>;
  setModelsList: Setter<ModelsListCompressed | undefined>;
  refetchModels: (
    info?: unknown,
  ) => ModelsList | Promise<ModelsList | undefined> | null | undefined;
  refetchDatasets: (info?: unknown) => DatasetList | Promise<DatasetList | undefined> | null | undefined
  getDatasetsList: Accessor<DatasetListCompressed | undefined>;
  client: GraphQLClientQuery;
}

export default function ModelsOverview(props: ModelsListProps) {
  const [t] = useI18n();

  const [getSingleModel, setSingleModel] =
    createStore<SingleModelType>(_emptyModel);

  //For confirmationboxes
  const [showConfigModel, setShowConfigModel] = createSignal(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    createSignal(false);
  const [showErrorMessage, setShowErrorMessage] = createSignal(false);

  //For handling changes
  const [changesMade, setChangesMade] = createSignal<boolean>(false);
  const [modelId, setModelId] = createSignal<string>("");
  const [modelToDeleteId, setModelToDeleteId] = createSignal<string>("");
  const [modelToDeleteName, setModelToDeleteName] = createSignal<string>("");

  //Set to false as not to fire query on mount
  const [deleteQueryInput, setDeleteQueryInput] = createSignal<
    boolean | Object
  >(false);

  const [deleteModelResponse] = props.client<boolean>(
    _DeleteModel,
    deleteQueryInput,
  );

  //Subsribers to changes
  createEffect(() => {
    if (
      !deleteModelResponse.loading &&
      !deleteModelResponse.error &&
      deleteModelResponse()
    ) {
      console.log("Delete successful. Updating list");
      setShowErrorMessage(false);
      props.refetchModels();
      window.location.href = "#modeloverview";
    }
    if (deleteModelResponse.error) {
      console.log(deleteModelResponse.error);
      setShowErrorMessage(true);
    }
  });

  //Handlers and functions
  const configHandler = (model: SingleModelType) => {
    setSingleModel(model);
    setModelId(model.id);
    setShowConfigModel(true);
    location.href = "#singlemodeloverview";
  };

  const deleteModelHandler = (input: { id: string; name: string }) => {
    setModelToDeleteId(input.id);
    setModelToDeleteName(input.name);
    setShowDeleteConfirmationBox(true);
  };

  const deleteModelFunction = () => {
    if (modelToDeleteId() !== "") {
      const input = { input: { id: modelToDeleteId() } };
      console.log(input);
      setDeleteQueryInput(input);
    }
  };

  const sortedModelsList = () => {
    return props.getModelsList()?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <>
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="shadow-sm ring-1 ring-black ring-opacity-5">
          <table
            class="min-w-full border-separate"
            style={{
              "border-spacing": "0",
            }}
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  {t("name", {}, "Name")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("lastUpdated", {}, "Last updated")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("id", {}, "ID")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
              </tr>
            </thead>
            <tbody class="bg-white">
              <Switch
                fallback={
                  <For each={sortedModelsList()}>
                    {(model) => (
                      <tr>
                        <td class="whitespace-nowrap w-3/12 border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {model.name}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {new Date(model.updatedAt).toLocaleDateString(
                            "sv-SE",
                          )}{" "}
                          {new Date(model.updatedAt).toLocaleTimeString(
                            "sv-SE",
                          )}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {model.id}{" "}
                          <a
                            onClick={() =>
                              navigator.clipboard.writeText(model.id)
                            }
                            class="cursor-pointer active:animate-ping text-black active:text-green-400 m-1"
                          >
                            <CopyIcon />
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8 cursor-pointer">
                          <a
                            onClick={() => configHandler(model)}
                            class="text-indigo-600 hover:text-indigo-900"
                          >
                            {t("config", {}, "Config")}
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8 cursor-pointer">
                          <a
                            onClick={() =>
                              deleteModelHandler({
                                id: model.id,
                                name: model.name,
                              })
                            }
                            class="text-red-600 hover:text-red-900 cursor-pointer"
                          >
                            {t("delete", {}, "Delete")}
                          </a>
                        </td>
                      </tr>
                    )}
                  </For>
                }
              >
                <Match when={props.getModelsList()?.length === 0}>
                  <tr>
                    <td colspan="6">
                      <div class="mx-auto my-2 py-1 rounded-full text-center">
                        No current models
                      </div>
                    </td>
                  </tr>
                </Match>
              </Switch>
            </tbody>
          </table>
        </div>
      </div>
      <Show when={showErrorMessage()}>
        <p class="block text-sm font-medium text-red-700 m-2">
          *Something went wrong, could not delete. Make sure{" "}
          {modelToDeleteName()} is not using any Datasets before making another
          attempt.
        </p>
      </Show>
      <Show when={deleteModelResponse.loading}>
        <div class="m-5">
          <SpinnerIcon />
          {t("deleting", {}, "Deleting")}...
        </div>
      </Show>
      <Show when={showConfigModel() && getSingleModel.id !== ""}>
        <ConfigModelOverview
          getSingleModel={getSingleModel}
          setSingleModel={setSingleModel}
          modelId={modelId}
          refetchModels={props.refetchModels}
          refetchDatasets={props.refetchDatasets}
          getDatasetsList={props.getDatasetsList}
          changesMade={changesMade}
          setChangesMade={setChangesMade}
          showOverview={setShowConfigModel}
          client={props.client}
        />
      </Show>
      <Show when={showDeleteConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteConfirmationBox}
          deleteFunction={deleteModelFunction}
          nameToDelete={modelToDeleteName()}
          idToDelete={modelToDeleteId()}
          type={EntityTypes.model}
        />
      </Show>
    </>
  );
}
