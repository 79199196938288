import { createEffect, onCleanup, onMount, Suspense } from "solid-js";
import createRAF, { targetFPS } from "@solid-primitives/raf";
import {
  AmbientLight,
  BoxGeometry,
  Color,
  DirectionalLight,
  DoubleSide,
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  MeshNormalMaterial,
  MeshPhongMaterial,
  OrthographicCamera,
  PerspectiveCamera,
  PlaneGeometry,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";

interface FilledBoxProps {
  levels: {
    heightMap: number[][];
  };
}

export default function FilledBox(props: FilledBoxProps) {
  let divRef: HTMLDivElement;
  let canvasRef: HTMLCanvasElement;

  let ambientLight,
    box,
    boxEdges,
    boxMaterial,
    camera,
    scene,
    boxGeometry,
    edgesGeometry,
    light,
    lineMaterial,
    material,
    plane,
    planeGeometry,
    renderer,
    volume,
    volumeGeometry;

  const [, start] = createRAF(
    targetFPS(() => {
      if (!box || !volume || !renderer) {
        return;
      }
      // plane.rotation.x += 0.002;
      // plane.rotation.z += 0.004;
      volume.rotation.z += 0.004;
      // boxEdges.rotation.x += 0.002;
      box.rotation.z += 0.004;
      renderer.render(scene, camera);
    }, 60),
  );

  createEffect(() => {
    console.log(props.levels);
    if (!volumeGeometry || !volume) {
    // if (!props.levels || !props.levels.heightMap || !volumeGeometry || !volume) {
      return;
    }
    const nRows = 101;
    console.log("rows", nRows);
    const nCols = 101;
    console.log("cols", nCols);

    const attPos = volumeGeometry.getAttribute("position");
    console.log("count", attPos.count);

    for (let i = 0; i < attPos.count; i++) {
      attPos.setZ(i, 0.2);
    }

    for (let j = 1; j < 4; j++) {
      const xLower = Math.random() * 0.7;
      const xUpper = xLower + Math.random() * (1 - xLower);
      const yLower = Math.random() * 0.7;
      const yUpper = yLower + Math.random() * (1 - yLower);

      for (let i = 0; i < attPos.count; i++) {
        const x = attPos.getX(i);
        const y = attPos.getY(i);
        const z = attPos.getZ(i);
        if (z < 0.23) {
          const xPos = (x + 0.49) / 0.98;
          const yPos = (y + 0.49) / 0.98;
          if (xPos > xLower && xPos < xUpper && yPos > yLower && yPos < yUpper) {
            const ix = Math.min(49, Math.max(0, Math.floor(xPos * 49)));
            const iy = Math.min(49, Math.max(0, Math.floor(yPos * 49)));
            // attPos.setZ(i, (ix / 100) * 0.5 - 0.25);
            // attPos.setZ(i, 0.23 - props.levels.heightMap[ix][iy] * 0.48);
            // attPos.setZ(i, 0.3);
            attPos.setZ(i, Math.min(0.22, -0.1 + z * 0.75));
          } else {
            // attPos.setZ(i, 0.2);
          }
        }
      }
    }
    // volumeGeometry.computeVertexNormals();
    attPos.needsUpdate = true;
    // volume.updateMatrix();
    console.log("updated matrix");
  });

  onMount(() => {
    renderer = new WebGLRenderer({
      canvas: canvasRef,
      antialias: true,
      alpha: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    console.log("client dims", divRef.clientWidth, divRef.clientHeight);
    renderer.setSize(divRef.clientWidth, divRef.clientHeight);

    camera = new PerspectiveCamera(
      35,
      2, // divRef.clientWidth / divRef.clientHeight,
      0.01,
      10,
    );
    camera.position.z = 2.3;
    camera.position.y = -0.2;

    scene = new Scene();
    // scene.background = new Color(0xffffff);
    planeGeometry = new PlaneGeometry(0.7, 0.7, 10, 10);
    boxGeometry = new BoxGeometry(1, 1, 0.5);
    volumeGeometry = new BoxGeometry(0.98, 0.98, 0.48, 50, 50, 1);
    boxGeometry.computeVertexNormals();
    // edgesGeometry = new EdgesGeometry(boxGeometry);

    material = new MeshPhongMaterial({
      color: 0x531eff,
      flatShading: true,
      // opacity: 0.9,
      side: DoubleSide,
      specular: 0x8e6cff,
      transparent: false,
      // vertexColors: true,
      // wireframe: true,
    });
    boxMaterial = new MeshPhongMaterial({
      color: 0x85b675,
      flatShading: true,
      opacity: 0.35,
      side: DoubleSide,
      specular: 0x9ED98B,
      transparent: true,
      // vertexColors: true,
      // wireframe: true,
    });

    // lineMaterial = new LineBasicMaterial();
    // box = new LineSegments(edgesGeometry, lineMaterial);

    let attPos = planeGeometry.getAttribute("position");
    for (let i = 0; i < attPos.count; i++) {
      const x = attPos.getX(i);
      const y = attPos.getY(i);
      const z = attPos.getZ(i);
      // const y = geometry.getAttribute("z");
      // geometry.setAttribute("y", x / 25);
      attPos.setZ(i, Math.random() / 10);
    }
    planeGeometry.computeVertexNormals();

    attPos = volumeGeometry.getAttribute("position");
    for (let i = 0; i < attPos.count; i++) {
      const x = attPos.getX(i);
      const y = attPos.getY(i);
      const z = attPos.getZ(i);
      if (z < 0.23) {
        attPos.setZ(i, 0.2);
      }
    }
    volumeGeometry.computeVertexNormals();

    // box = new Mesh(boxGeometry, material);
    plane = new Mesh(planeGeometry, material);
    plane.rotation.x = 2.3;
    plane.rotation.y = 0.2;

    volume = new Mesh(volumeGeometry, material);
    volume.rotation.x = 2.2;
    // volume.rotation.y = 0.2;

    box = new Mesh(boxGeometry, boxMaterial);
    box.rotation.x = 2.2;
    // box.rotation.y = 0.2;

    ambientLight = new AmbientLight(0xffffff, 0.5); // soft white light

    const light = new DirectionalLight(0xffffff, 0.5);
    light.position.set(0.0, 2, 2.5);
    light.castShadow = false;

    light.shadow.mapSize.width = 512; // default
    light.shadow.mapSize.height = 512; // default

    light.shadow.camera = new OrthographicCamera(-50, 50, 50, -50, 0.5, 1000);

    scene.add(box);
    // scene.add(plane);
    scene.add(volume);
    scene.add(ambientLight);
    scene.add(light);

    start();
  });

  onCleanup(() => {
    if (boxGeometry) {
      boxGeometry.dispose();
    }
    // edgesGeometry.dispose();
    if (planeGeometry) {
      planeGeometry.dispose();
    }
    if (material) {
      material.dispose();
    }
  });

  // createEffect(() => {
  //   if (props.levels) {
  //     // let v3 = new Vector3();
  //     for (let i = 0; i < geometry.attributes.position.count; i++) {
  //       const x = geometry.getAttribute("x");
  //       // const y = geometry.getAttribute("z");
  //       geometry.setAttribute("y", x / 25);
  //     }
  //   }
  // });

  return (
    <Suspense>
      <div ref={divRef}>
        <canvas class="" ref={canvasRef!} height={200} width={100}></canvas>
      </div>
    </Suspense>
  );
}
