import { Icon } from "solid-heroicons";
import {
  checkCircle,
  handThumbDown,
  handThumbUp,
  tag,
  xCircle,
} from "solid-heroicons/outline";
import { flag } from "solid-heroicons/solid";

export function CheckCircleIcon() {
  return <Icon class="fill-nl-green-900 h-6 ml-1 w-6" path={checkCircle} />;
}

export function CheckCircleIconTransparent() {
  return <Icon class="fill-transparent h-6 w-6" path={checkCircle} />;
}

export function FlagIcon() {
  return <Icon aria-hidden="true" class="text-gray-300 h-6 w-6" path={flag} />;
}

export function TagIcon(props: { color?: string }) {
  return (
    <Icon
      class="h-4 fill-nl-gray-dark-100 w-4"
      path={tag}
      style={{ color: props.color ?? "inherit" }}
    />
  );
}

export function ThumbIcon(props: { isUp: boolean }) {
  return (
    <Icon
      class="h-4 mr-1 w-4"
      path={props.isUp ? handThumbUp : handThumbDown}
    />
  );
}

export function XCircleIcon() {
  return <Icon class="fill-nl-red-900 h-6 ml-1 w-6" path={xCircle} />;
}

export function XCircleIconTransparent() {
  return <Icon class="fill-transparent h-6 w-6" path={xCircle} />;
}
