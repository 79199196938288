import { Setter, Show, createSignal } from "solid-js";
import { _DeleteClassLabel } from "../helpers/mutations";
import { EntityTypes } from "../helpers/types";

interface DeleteConfirmationDialogProps {
  showDialog: Setter<boolean>;
  deleteFunction: (id: string) => void;
  nameToDelete: string;
  idToDelete: string;
  type: EntityTypes;
}

export default function DeleteConfirmationBox(
  props: DeleteConfirmationDialogProps,
) {
  var confirmInput!: HTMLInputElement;
  const [multistepConfirmation, setMultistepConfirmation] = createSignal(false);
  const [confirmationInput, setConfirmationInput] = createSignal<string>();

  const multistepConfirmationHandler = () => {
    setConfirmationInput(confirmInput.value);
  };

  return (
    <div class="fixed inset-0 z-40 min-h-full overflow-y-auto overflow-x-hidden transition flex items-center">
      <div
        aria-hidden="true"
        class="fixed inset-0 w-full h-full bg-black/50"
      ></div>

      <div class="relative w-full transition my-auto p-4">
        <div class="w-full py-2 bg-gray-100 relative rounded-xl mx-auto max-w-sm">
          <div class="space-y-2 p-2">
            <div class="p-4 space-y-2 text-center">
              <h2 class="text-xl font-bold tracking-tight">
                Delete "{props.nameToDelete}"
              </h2>

              <p class="text-gray-600">
                Deleting may impact other parts of your system. Are you sure?
              </p>
            </div>
          </div>

          <div class="space-y-2">
            <div
              aria-hidden="true"
              class="border-t dark:border-gray-700 px-2"
            ></div>
            <Show when={multistepConfirmation()}>
              <div class="px-6 py-2">
                <div class="flex flex-col items-center justify-center">
                  <p class="text-gray-900 text-sm">
                    Confirm by writing <i>delete</i> below
                  </p>
                  <input
                    type="text"
                    ref={confirmInput}
                    onInput={() => multistepConfirmationHandler()}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter" && confirmationInput() === "delete") {
                        props.deleteFunction(props.idToDelete);
                        props.showDialog(false);
                      }
                    }}
                    class="appearance-none rounded-md border border-gray-300 my-2 focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 shadow-sm text-center mb-3"
                  />
                </div>
                <div class="grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                  <a
                    class="inline-flex items-center justify-center py-1 gap-1 nl-button nl-button--xs m-1 bg-nl-red-800"
                    classList={{
                      "opacity-100 cursor-pointer hover:bg-nl-red-800": confirmationInput() === "delete",
                      "opacity-50 pointer-events-none":
                        confirmationInput() !== "delete",
                    }}
                    onclick={() => {
                      if (confirmationInput() === "delete") {
                        props.deleteFunction(props.idToDelete);
                        props.showDialog(false);
                      }
                    }}
                  >
                    Confirm
                  </a>

                  <a
                    class="inline-flex items-center justify-center py-1 gap-1 nl-button nl-button--xs m-1 bg-nl-gray-400 hover:bg-nl-gray-800 text-black cursor-pointer"
                    onClick={() => props.showDialog(false)}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </Show>

            <Show when={!multistepConfirmation()}>
              <div class="px-6 py-2">
                <div class="grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                  <a
                    class="inline-flex items-center justify-center py-1 gap-1 nl-button nl-button--xs m-1 bg-nl-red-400 hover:bg-nl-red-800 cursor-pointer"
                    onclick={() => {
                      if (
                        props.type === EntityTypes.dataset ||
                        props.type === EntityTypes.classLabel
                      ) {
                        setMultistepConfirmation(true);
                      } else {
                        props.deleteFunction(props.idToDelete);
                        props.showDialog(false);
                      }
                    }}
                  >
                    Delete
                  </a>

                  <a
                    class="inline-flex items-center justify-center py-1 gap-1 nl-button nl-button--xs m-1 bg-nl-gray-400 hover:bg-nl-gray-800 text-black cursor-pointer"
                    onClick={() => props.showDialog(false)}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
}
