import {
  createEffect,
  createSignal,
  For,
  Match,
  Show,
  Suspense,
  Switch,
  useContext,
} from "solid-js";
import { gql, GraphQLClientQuery } from "@solid-primitives/graphql";
import { useI18n } from "@solid-primitives/i18n";
import { request } from "../../../helpers/graphql";
import {
  Annotation,
  AnnotationOriginStatus,
  AnnotationTypes,
} from "../../../helpers/types";
import {
  CheckCircleIconTransparent,
  FlagIcon,
  TagIcon,
  XCircleIconTransparent,
} from "./Icons";
import { UserContext } from "../../../components/UserContext";

const _CreateImageAnnotationMutation = gql`
  mutation CreateImageAnnotationMutation($input: CreateImageAnnotationInput!) {
    createImageAnnotation(input: $input) {
      annotationData {
        annotationType
        singleLabelClassification {
          label {
            color
            id
            name
          }
        }
      }
      id
      originStatus
    }
  }
`;

// FIXME Throws exception when including
//    image {
//      annotationComplete
//    }
const _UpdateImageAnnotationMutation = gql`
  mutation UpdateImageAnnotationMutation(
    $updateImageAnnotationId: ID!
    $input: UpdateImageAnnotationInput!
  ) {
    updateImageAnnotation(id: $updateImageAnnotationId, input: $input) {
      annotationData {
        annotationType
        singleLabelClassification {
          label {
            color
            id
            name
          }
        }
      }
      id
      originStatus
    }
  }
`;

interface AnnotationComponentProps {  
  hasHumanAnnotation: boolean;
  hasAiAnnotation: boolean;
  annotations: Annotation[];
  classes?: { color: string; id: string; name: string }[];
  containerId?: string;  
  query: GraphQLClientQuery;
  refetchImageContainer: () => void; 
}

export default function AnnotationComponent(props: AnnotationComponentProps) {
  const [t] = useI18n();
  const user = useContext(UserContext);
  const [currentClass, setCurrentClass] = createSignal<string>();
  const [currentAnnotationId, setCurrentAnnotationId] = createSignal<string>();

  createEffect(() => {
    let humanAnnotationId;
    let humanClass;

    if (props.annotations) {
      for (const a of props.annotations) {
        const data = a.annotationData;
        if (a.originStatus === AnnotationOriginStatus.HumanGenerated) {
          humanClass = data.singleLabelClassification.label.id;
          humanAnnotationId = a.id;
        }
      }
    }

    setCurrentAnnotationId(humanAnnotationId);
    setCurrentClass(humanClass);
  });

  const updateAiAnnotation = (id: string, status: AnnotationOriginStatus) => {
    const annotationInput = {
      originStatus: status,
    };

    console.log("updateAiAnnotation", annotationInput);
    request(
      _UpdateImageAnnotationMutation,
      {
        updateImageAnnotationId: id,
        input: annotationInput,
      },
      user?.accessToken,
    )
      .then((res) => {
        console.log("updateAiAnnotation", res);
        updateAnnotation(
          props.containerId ?? "",
          res.updateImageAnnotation.annotationData.singleLabelClassification
            .label.id,
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateAnnotation = (
    containerId: string,
    annotationClass: string,
    annotationId?: string,
  ) => {
    const annotationData = {
      annotationType: AnnotationTypes.SingleLabel,
      singleLabelClassification: {
        labelId: annotationClass,
        confidence: 1.0,
      },
    };

    if (!annotationId) {
      const annotationInput = {
        ImageAnnotation_belongsToImageContainerID: containerId,
        annotationData: annotationData,
        originStatus: AnnotationOriginStatus.HumanGenerated,
      };
      console.log("annotationInput", annotationInput);

      request(
        _CreateImageAnnotationMutation,
        {
          input: annotationInput,
        },
        user?.accessToken,
      )
        .then((res) => {
          console.log("createAnnotation", res);
          props.refetchImageContainer();
        })
        .catch((e) => {
          console.error(e);
          props.refetchImageContainer();
        });
    } else {
      const annotationInput = {
        ImageAnnotation_belongsToImageContainerID: containerId,
        annotationData: annotationData,
      };
      console.log("annotationInput", annotationInput);
      console.log("annotationId", annotationId);

      request(
        _UpdateImageAnnotationMutation,
        {
          updateImageAnnotationId: annotationId,
          input: annotationInput,
        },
        user?.accessToken,
      )
        .then((res) => {
          console.log("updateAnnotation", res);
          props.refetchImageContainer();
        })
        .catch((e) => {
          console.error(e);
          props.refetchImageContainer();
        });
    }
  };

  return (
    <div>
      <Show when={!props.hasHumanAnnotation}>
        <div class="mt-2 text-white">
          {/* Currently, we always choose the buttons for editing human
              annotations and thus we do not need to show these annotations
              here */}
          {/* Show crossed over class if rejected */}
          <For each={props.annotations}>
            {(item) => (
              <>
                <div>
                  <span class="pr-1 italic">The AI classified this as</span>
                  <Switch
                    fallback={
                      <span class="font-semibold">
                        {
                          item.annotationData.singleLabelClassification.label
                            .name
                        }
                      </span>
                    }
                  >
                    <Match
                      when={
                        item.originStatus ===
                        AnnotationOriginStatus.AiGeneratedHumanApproved
                      }
                    >
                      <span class="align-top pl-1">
                        <CheckCircleIconTransparent />
                      </span>
                    </Match>
                    <Match
                      when={
                        item.originStatus ===
                        AnnotationOriginStatus.AiGeneratedHumanRejected
                      }
                    >
                      <span class="font-semibold line-through">
                        {
                          item.annotationData.singleLabelClassification.label
                            .name
                        }
                      </span>
                      <span class="align-top pl-1">
                        <XCircleIconTransparent />
                      </span>
                    </Match>
                  </Switch>
                </div>

                <Show
                  when={
                    item.originStatus === AnnotationOriginStatus.AiGenerated
                  }
                >
                  <div class="mt-2 space-y-5">
                    <div class="flex gap-4">
                      <button
                        class="nl-button nl-button--base"
                        onClick={() =>
                          updateAiAnnotation(
                            item.id,
                            AnnotationOriginStatus.AiGeneratedHumanApproved,
                          )
                        }
                      >
                        <span class="pr-1">
                          <CheckCircleIconTransparent />
                        </span>
                        {t("agree", {}, "Agree")}
                      </button>
                      <button
                        class="nl-button nl-button--base"
                        onClick={() =>
                          updateAiAnnotation(
                            item.id,
                            AnnotationOriginStatus.AiGeneratedHumanRejected,
                          )
                        }
                      >
                        <span class="pr-1">
                          <XCircleIconTransparent />
                        </span>
                        {t("disagree", {}, "Disagree")}
                      </button>
                    </div>
                  </div>
                </Show>
              </>
            )}
          </For>
        </div>
      </Show>

      {/* If there are no annotations, or there are human annotations,
          or there are overriden ai annotations - we need to show choices */}
      <Suspense>
        <Show
          when={
            props.annotations.length === 0 ||
            props.hasHumanAnnotation ||
            (props.annotations ?? []).every(
              (e) =>
                e.originStatus ===
                  AnnotationOriginStatus.AiGeneratedHumanApproved ||
                e.originStatus ===
                  AnnotationOriginStatus.AiGeneratedHumanRejected,
            )
          }
        >
          <form>
            <fieldset>
              <legend class="sr-only">Classes</legend>
              <div class="mt-2 space-y-5">
                <div class="flex gap-4 flex-wrap">
                  <For each={props.classes}>
                    {(item) => (
                      <button
                        class="flex gap-1.5 items-center border border-black border-opacity-20 h-8 transition px-2 py-1 font-medium text-sm uppercase rounded-md text-nl-gray-200"
                        onClick={(e) => {
                          e.preventDefault();
                          updateAnnotation(
                            props.containerId ?? "",
                            item.id,
                            currentAnnotationId(),
                          );
                        }}
                        style={{
                          "background-color":
                            currentClass() === item.id
                              ? item.color
                              : "hsl(225,  7%, 65%)",
                        }}
                      >
                        <TagIcon color="#888" />
                        <span class="text-black text-opacity-70">
                          {item.name}
                        </span>
                      </button>
                    )}
                  </For>
                </div>

                <Show when={false}>
                  <div class="flex gap-2 px-3">
                    <FlagIcon />
                    <div>
                      <div class="text-lg text-nl-gray-dark-400 font-black tracking-wide">
                        {t("flagForReview", {}, "Flag for review")}
                      </div>
                      {/*
                      <div class="text-xs uppercase text-nl-gray-dark-400 tracking-wide">
                        Your work is saved
                      </div>
                      */}
                    </div>
                  </div>
                </Show>
              </div>
            </fieldset>
          </form>
        </Show>
      </Suspense>
    </div>
  );
}
