import { Auth } from "aws-amplify";
import { createEffect, For, Match, Show, Switch } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { createFormGroup, createFormControl } from "solid-forms";

import { TextInput } from "../../../components/TextInput";
import { validators } from "../../../helpers/validators";
import { AuthForgotPasswordSubmitErrorCode } from "../../../helpers/types";

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const group = createFormGroup({
    code: createFormControl("", {
      required: true,
      validators: [validators.required],
    }),
    password: createFormControl("", {
      required: true,
      validators: [validators.required, validators.password],
    }),
    usernameOrEmail: createFormControl("", {
      required: true,
      validators: [validators.required, validators.usernameOrEmail],
    }),
  });

  createEffect(() => {
    if (group.isDisabled || !group.isValid) {
      return;
    }
    console.log("Current group value", group.value);
  });

  async function forgotPasswordSubmit(
    usernameOrEmail: string,
    code: string,
    password: string,
    group: any,
  ) {
    try {
      await Auth.forgotPasswordSubmit(usernameOrEmail, code, password);
    } catch (error) {
      const { code, message }: {
        code: AuthForgotPasswordSubmitErrorCode | undefined;
        message: string | undefined;
      } = error;

      console.log("error forgot password submit in: ", error);

      group.markSubmitted(false);
      switch (code) {
        case AuthForgotPasswordSubmitErrorCode.USER_NOT_FOUND:
          group.setErrors({
            userNotFound: "No user with that username or email address.",
          });
          break;
        case AuthForgotPasswordSubmitErrorCode.EXPIRED_CODE:
          group.setErrors({
            userNotFound: "Verification code has expired.",
          });
          break;
        case AuthForgotPasswordSubmitErrorCode.MISMATCHED_CODE:
          group.setErrors({
            userNotFound: "Invalid verification code.",
          });
          break;
        default:
          group.setErrors({
            unknownError: message,
          });
      }

      return;
    }

    navigate("/sign-in", { replace: true });
  }

  const submitHandler = (e: Event) => {
    e.preventDefault();

    if (group.isSubmitted) {
      console.warn("Forgot password form is already submitted.");
      return;
    }

    // Need to clear group-wide errors so form is submitted.
    group.setErrors(null);

    if (!group.isValid) {
      console.warn("Invalid form");
      return;
    }

    group.markSubmitted(true);

    const { code, password, usernameOrEmail } = group.value;
    forgotPasswordSubmit(
      usernameOrEmail ?? "",
      code ?? "",
      password ?? "",
      group,
    );
  };

  return (
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Set your new password
        </h2>

        <p class="mt-2 text-center text-sm text-gray-600">
          A verification code has been sent to the email address associated with
          your account. Please enter the code below.
        </p>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={submitHandler} class="space-y-6">
            <div>
              <label
                for="usernameOrEmail"
                class="block text-sm font-medium text-gray-700"
              >
                Username or Email Address
              </label>
              <div class="mt-1">
                <TextInput
                  autocomplete="email username"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                  control={group.controls.usernameOrEmail}
                  name="usernameOrEmail"
                  type="text"
                />
              </div>
            </div>

            <div>
              <label for="code" class="block text-sm font-medium text-gray-700">
                Verification Code
              </label>
              <div class="mt-1">
                <TextInput
                  autocomplete="off"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                  control={group.controls.code}
                  name="code"
                  type="text"
                />
              </div>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div class="mt-1">
                <TextInput
                  autocomplete="off"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                  control={group.controls.password}
                  name="password"
                  type="password"
                />
              </div>
            </div>

            <Show when={!group.self.isValid}>
              <div class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg
                      class="h-5 w-5 text-red-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      <Switch fallback={"There were errors during sign in"}>
                        <Match
                          when={Object.values(group.self.errors).length === 1}
                        >
                          There was an error with your submission
                        </Match>
                        <Match
                          when={Object.values(group.self.errors).length > 1}
                        >
                          There were {Object.values(group.self.errors).length}{" "}
                          errors with your submission
                        </Match>
                      </Switch>
                    </h3>
                    <div class="mt-2 text-sm text-red-700">
                      <ul role="list" class="list-disc space-y-1 pl-5">
                        <For each={Object.values(group.self.errors)}>
                          {(errorMsg: string) => <li>{errorMsg}</li>}
                        </For>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Show>

            <div>
              <button
                type="submit"
                class="flex w-full justify-center rounded-md border border-transparent bg-nl-blue-royal-900 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-nl-blue-royal-700 focus:outline-none focus:ring-2 focus:ring-nl-blue-royal-700 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
