import { Show } from "solid-js";
import { A } from "@solidjs/router";

export default function StatisticsPage() {
  return (
    <div>
      <h1>Statistics</h1>
      <A href="/sign-in">Sign In</A>
      <Show when={import.meta.env.VITE_ENABLE_REGISTRATION === "true"}>
        {" "}
        | <A href="/sign-up">Sign Up</A>
      </Show>
    </div>
  );
}
