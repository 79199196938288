import { Icon } from "solid-heroicons";
import {
  arrowPath,
  backward,
  exclamationCircle,
  documentDuplicate,
  chevronDoubleRight,
  check,
  plusCircle, xCircle
} from "solid-heroicons/outline";

export function SpinnerIcon() {
  return (
    <Icon
      aria-hidden="true"
      class="animate-spin h-6 inline-block mx-2 w-6"
      path={arrowPath}
    />
  );
}

export function DownloadAndAbortIcon() {
  return (
    <Icon
    aria-hidden="true"
    class="animate-pulse h-7 inline-block mx-1"
    path={xCircle}
  />);
}

export function ErrorIcon() {
  return (
    <Icon
      aria-hidden="true"
      class="h-6 inline-block mr-2 w-6"
      path={exclamationCircle}
    />
  );
}

export function CopyIcon() {
  return (
    <Icon
      aria-hidden="true"
      class="h-6 inline-block mr-2 w-6"
      path={documentDuplicate}
    />
  );
}

export function ChevronDoubleRight() {
  return <Icon aria-hidden="true" class="w-6" path={chevronDoubleRight} />;
}

export function PlusCircle() {
  return (
    <Icon
      aria-hidden="true"
      class="mx-2 w-8 h-8 cursor-pointer text-nl-blue-royal-500 hover:text-nl-blue-royal-900"
      path={plusCircle}
    />
  );
}

export function Check() {
  return <Icon aria-hidden="true" class="mx-1" path={check} />;
}

export function Backward() {
  return <Icon aria-hidden="true" class="inline mx-2 pb-1 w-9 h-9 cursor-pointer" path={backward} />;
}
