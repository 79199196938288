import { Show } from "solid-js";
import { A } from "@solidjs/router"

function StartPage() {
  return (
    <div class="h-screen">
      <div class="py-10">
        <header>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              Start
            </h1>
          </div>
        </header>
        <main>
          <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <A href="/sign-in">Sign In</A>
            <Show when={import.meta.env.VITE_ENABLE_REGISTRATION === "true"}>
              {" "}
              | <A href="/sign-up">Sign Up</A>
            </Show>
          </div>
        </main>
      </div>
    </div>
  );
}

export default StartPage;
