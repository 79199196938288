import DemoLogo from "../svgs/demo-logo.svg?component-solid";
import MondiLogo from "../svgs/mondi-logo.svg?component-solid";

export function Logo() {
  if (import.meta.env.VITE_CUSTOMER === "mondi") {
    // eslint-disable-next-line solid/components-return-once
    return <MondiLogo class="w-[120px] -mt-[10px]" />;
  } else {
    // eslint-disable-next-line solid/components-return-once
    return <DemoLogo class="w-[200px]" />;
  }
}
