import {
  Match,
  Show,
  Switch,
  createEffect,
  createSignal,
  useContext,
} from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import {
  _CreateDataset,
  _CreateMachine,
  _CreateModel,
  _CreateSource,
  _CreateTask,
  _DeleteDataset,
  _UpdateDataset,
} from "../../helpers/mutations";
import { UserContext } from "../../components/UserContext";
import { createGraphQLClient } from "../../helpers/graphql";
import {
  DatasetList,
  MachineList,
  DatasetListCompressed,
  MachineListCompressed,
  TasksListCompressed,
  ModelsList,
  ModelsListCompressed,
  TasksListExtended,
  EntityTypes,
  SourceList,
  SourceListCompressed,
} from "../../helpers/types";
import DatasetOverview from "./components/DatasetOverview";
import MachineOverview from "./components/MachineOverview";
import {
  _ListDatasetsQuery,
  _ListMachinesQuery,
  _ListModelsQuery,
  _ListTasksQueryExtended,
  _ListSourceQuery,
} from "../../helpers/queries";
import {
  _CreateDatasetsUsedByModel,
  _CreateImageContainer,
  _DeleteMachine,
} from "../../helpers/mutations";
import { ErrorIcon, PlusCircle, SpinnerIcon } from "../../components/Icons";
import TasksOverview from "./components/TaskOverview";
import ModelsOverview from "./components/ModelOverview";
import { AddObject } from "./components/AddObject";
import SourceOverview from "./components/SourceOverview";
import { useNavigate } from "@solidjs/router";

export default function ServicePage() {
  const [t] = useI18n();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const client = createGraphQLClient(user?.accessToken);

  if (user?.group !== "admin") {
    console.log("User not authorized. Redirecting to dashboard");
    navigate("/dashboard", { replace: true });
    return;
  }
  
  //For AddObject-component
  const [typeToBeAdded, setTypeToBeAdded] = createSignal<EntityTypes>(
    EntityTypes.default,
  );
  const [addFunction, setAddFunction] = createSignal<(input: object) => void>(
    () => {},
  );

  //For regretting changes to machine config
  const [originalConfig, setOriginalConfig] = createSignal<
    { machineID: string; originalConfig: string } | undefined
  >();

  //Input-signals and mutations for adding new objects, set to false as not to fire on mount
  const [createTaskInput, setCreateTaskInput] = createSignal<object | boolean>(
    false,
  );
  const [createTaskResponse] = client(_CreateTask, createTaskInput);

  const [createMachineInput, setCreateMachineInput] = createSignal<
    object | boolean
  >(false);
  const [createMachineResponse] = client(_CreateMachine, createMachineInput);

  const [createSourceInput, setCreateSourceInput] = createSignal<
    object | boolean
  >(false);
  const [createSourceResponse] = client(_CreateSource, createSourceInput);

  const [createDatasetInput, setCreateDatasetInput] = createSignal<
    object | boolean
  >(false);
  const [createDatasetResponse] = client(_CreateDataset, createDatasetInput);

  const [createModelInput, setCreateModelInput] = createSignal<
    object | boolean
  >(false);
  const [createModelResponse] = client(_CreateModel, createModelInput);

  //Create-subscribers
  createEffect(() => {
    if (
      !createTaskResponse.error &&
      !createTaskResponse.loading &&
      createTaskResponse()
    ) {
      setCreateTaskInput(false);
      window.location.href = "#taskoverview";
      refetchTasks();
    }
  });

  createEffect(() => {
    if (
      !createMachineResponse.error &&
      !createMachineResponse.loading &&
      createMachineResponse()
    ) {
      setCreateMachineInput(false);
      window.location.href = "#machineoverview";
      refetchMachines();
    }

    if (createTaskResponse.error) {
      console.log(createTaskResponse.error);
    }
  });

  createEffect(() => {
    if (
      !createSourceResponse.error &&
      !createSourceResponse.loading &&
      createSourceResponse()
    ) {
      setCreateSourceInput(false);
      window.location.href = "#sourcesoverview";
      refetchSources();
    }
  });

  createEffect(() => {
    if (
      !createDatasetResponse.error &&
      !createDatasetResponse.loading &&
      createDatasetResponse()
    ) {
      setCreateDatasetInput(false);
      window.location.href = "#datasetoverview";
      refetchDatasets();
    }
  });

  createEffect(() => {
    if (
      !createModelResponse.error &&
      !createModelResponse.loading &&
      createModelResponse()
    ) {
      setCreateModelInput(false);
      window.location.href = "#modeloverview";
      refetchModels();
    }
  });

  //Fetch data-queries
  const [tasksList, { refetch: refetchTasks }] = client<TasksListExtended>(
    _ListTasksQueryExtended,
  );
  const [machinesList, { refetch: refetchMachines }] =
    client<MachineList>(_ListMachinesQuery);
  const [sourcesList, { refetch: refetchSources }] =
    client<SourceList>(_ListSourceQuery);
  const [datasetsList, { refetch: refetchDatasets }] =
    client<DatasetList>(_ListDatasetsQuery);
  const [modelsList, { refetch: refetchModels }] =
    client<ModelsList>(_ListModelsQuery);

  const [getTasksList, setTasksList] = createSignal<TasksListCompressed>();
  const [getMachinesList, setMachinesList] =
    createSignal<MachineListCompressed>();
  const [getSourcesList, setSourcesList] = createSignal<SourceListCompressed>();
  const [getDatasetsList, setDataSetsList] =
    createSignal<DatasetListCompressed>();
  const [getModelsList, setModelsList] = createSignal<ModelsListCompressed>();

  //Fetch-subscribers
  createEffect(() => {
    if (tasksList === undefined) {
      return;
    }
    console.log("Taskslist: ", tasksList());

    if (tasksList()?.listTask) {
      setTasksList(tasksList()?.listTask.items);
    }
  });

  createEffect(() => {
    if (machinesList === undefined) {
      return;
    }
    console.log("Machineslist: ", machinesList());

    if (machinesList()?.listMachine) {
      setMachinesList(machinesList()?.listMachine.items);
    }
  });

  createEffect(() => {
    if (sourcesList === undefined) {
      return;
    }
    console.log("Sourceslist: ", machinesList());

    if (sourcesList()?.listSource) {
      setSourcesList(sourcesList()?.listSource.items);
    }
  });

  createEffect(() => {
    if (datasetsList === undefined) {
      return;
    }
    console.log("Datasetslist: ", datasetsList());

    if (datasetsList()?.listDataset) {
      setDataSetsList(datasetsList()?.listDataset.items);
    }
  });

  createEffect(() => {
    if (modelsList === undefined) {
      return;
    }
    console.log("Modelslist: ", modelsList());

    if (modelsList()?.listModel) {
      setModelsList(modelsList()?.listModel.items);
    }
  });

  function AddTask(input: object) {
    setCreateTaskInput(input);
  }
  function AddMachine(input: object) {
    setCreateMachineInput(input);
  }
  function AddSource(input: object) {
    setCreateSourceInput(input);
  }
  function AddDataset(input: object) {
    setCreateDatasetInput(input);
  }
  function AddModel(input: object) {
    setCreateModelInput(input);
  }

  return (
    <div class="min-h-full">
      <div class="py-10">
        <header>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {t("service", {}, "Service")}
            </h1>
          </div>
        </header>
        <main>
          <div class="mx-auto max-w-7xl sm:px-6 lg:px-8" id="taskoverview">
            <div class="px-4 py-8 sm:px-0">
              <div class="flex flex-row justify-between">
                <h2 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-2">
                  {t("tasks", {}, "Tasks")}
                </h2>
                <a
                  onClick={() => {
                    setTypeToBeAdded(EntityTypes.task);
                    setAddFunction(() => AddTask);
                    window.location.href = "#add";
                  }}
                >
                  <PlusCircle />
                </a>
              </div>
              <Switch
                fallback={
                  <Show
                    when={
                      !tasksList.loading &&
                      !tasksList.error &&
                      tasksList()?.listTask
                    }
                  >
                    <TasksOverview
                      getTasksList={getTasksList}
                      setTasksList={setTasksList}
                      refetchTasks={refetchTasks}
                      getMachinesList={getMachinesList}
                      getModelsList={getModelsList}
                      client={client}
                    ></TasksOverview>
                  </Show>
                }
              >
                <Match when={tasksList.error}>
                  <ErrorIcon />
                  Data could not be loaded |{" "}
                  <button
                    onClick={() => refetchTasks()}
                    class="text-indigo-600 hover:text-indigo-900 "
                  >
                    {" "}
                    RETRY{" "}
                  </button>
                </Match>
                <Match when={tasksList.loading}>
                  <SpinnerIcon />
                  {t("loading", {}, "Loading")}...
                </Match>
              </Switch>
            </div>
            <div class="px-4 py-8 sm:px-0" id="machineoverview">
              <div class="flex flex-row justify-between">
                <h2 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-2">
                  {t("machines", {}, "Machines")}
                </h2>
                <a
                  onClick={() => {
                    setTypeToBeAdded(EntityTypes.machine);
                    setAddFunction(() => AddMachine);
                    window.location.href = "#add";
                  }}
                >
                  <PlusCircle />
                </a>
              </div>
              <Switch
                fallback={
                  <Show
                    when={
                      !machinesList.loading &&
                      !machinesList.error &&
                      machinesList()?.listMachine
                    }
                  >
                    <MachineOverview
                      getMachinesList={getMachinesList}
                      setMachinesList={setMachinesList}
                      refetchMachines={refetchMachines}
                      originalConfig={originalConfig}
                      setOriginalConfig={setOriginalConfig}
                      client={client}
                    ></MachineOverview>
                  </Show>
                }
              >
                <Match when={machinesList.error}>
                  <ErrorIcon />
                  Data could not be loaded |{" "}
                  <button
                    onClick={() => refetchMachines()}
                    class="text-indigo-600 hover:text-indigo-900 "
                  >
                    {" "}
                    RETRY{" "}
                  </button>
                </Match>
                <Match when={machinesList.loading}>
                  <SpinnerIcon />
                  {t("loading", {}, "Loading")}...
                </Match>
              </Switch>
            </div>
            <div class="px-4 py-8 sm:px-0" id="sourcesoverview">
              <div class="flex flex-row justify-between">
                <h2 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-2">
                  {t("sources", {}, "Sources")}
                </h2>
                <a
                  onClick={() => {
                    setTypeToBeAdded(EntityTypes.source);
                    setAddFunction(() => AddSource);
                    window.location.href = "#add";
                  }}
                >
                  <PlusCircle />
                </a>
              </div>
              <Switch
                fallback={
                  <Show
                    when={
                      !sourcesList.loading &&
                      !sourcesList.error &&
                      sourcesList()?.listSource
                    }
                  >
                    <SourceOverview
                      getSourcesList={getSourcesList}
                      setSourcesList={setSourcesList}
                      refetchSources={refetchSources}
                      client={client}
                    ></SourceOverview>
                  </Show>
                }
              >
                <Match when={sourcesList.error}>
                  <ErrorIcon />
                  Data could not be loaded |{" "}
                  <button
                    onClick={() => refetchSources()}
                    class="text-indigo-600 hover:text-indigo-900 "
                  >
                    {" "}
                    RETRY{" "}
                  </button>
                </Match>
                <Match when={sourcesList.loading}>
                  <SpinnerIcon />
                  {t("loading", {}, "Loading")}...
                </Match>
              </Switch>
            </div>
            <div class="px-4 py-8 sm:px-0" id="datasetoverview">
              <div class="flex flex-row justify-between">
                <h2 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-2">
                  {t("datasets", {}, "Datasets")}
                </h2>
                <a
                  onClick={() => {
                    setTypeToBeAdded(EntityTypes.dataset);
                    setAddFunction(() => AddDataset);
                    window.location.href = "#add";
                  }}
                >
                  <PlusCircle />
                </a>
              </div>
              <Switch
                fallback={
                  <Show when={datasetsList() !== undefined}>
                    <DatasetOverview
                      getDataSetsList={getDatasetsList}
                      setDataSetsList={setDataSetsList}
                      refetchDatasets={refetchDatasets}
                      client={client}
                    ></DatasetOverview>
                  </Show>
                }
              >
                <Match when={datasetsList.error}>
                  <ErrorIcon />
                  Data could not be loaded |
                  <a
                    onClick={() => refetchDatasets()}
                    class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                  >
                    RETRY
                  </a>
                </Match>
                <Match when={datasetsList.loading}>
                  <SpinnerIcon />
                  {t("loading", {}, "Loading")}...
                </Match>
              </Switch>
            </div>
            <div class="px-4 py-8 sm:px-0" id="modeloverview">
              <div class="flex flex-row justify-between items-center">
                <h2 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-2">
                  {t("models", {}, "Models")}
                </h2>
                <a
                  onClick={() => {
                    setTypeToBeAdded(EntityTypes.model);
                    setAddFunction(() => AddModel);
                    window.location.href = "#add";
                  }}
                >
                  <PlusCircle />
                </a>
              </div>
              <Switch
                fallback={
                  <Show
                    when={
                      !modelsList.loading &&
                      !modelsList.error &&
                      modelsList()?.listModel
                    }
                  >
                    <ModelsOverview
                      getModelsList={getModelsList}
                      setModelsList={setModelsList}
                      getDatasetsList={getDatasetsList}
                      refetchModels={refetchModels}
                      refetchDatasets={refetchDatasets}
                      client={client}
                    ></ModelsOverview>
                  </Show>
                }
              >
                <Match when={modelsList.error}>
                  <ErrorIcon />
                  Data could not be loaded |{" "}
                  <button
                    onClick={() => refetchModels()}
                    class="text-indigo-600 hover:text-indigo-900"
                  >
                    {" "}
                    RETRY{" "}
                  </button>
                </Match>
                <Match when={modelsList.loading}>
                  <SpinnerIcon />
                  {t("loading", {}, "Loading")}...
                </Match>
              </Switch>
            </div>
            <Show when={typeToBeAdded() !== EntityTypes.default}>
              <AddObject
                type={typeToBeAdded}
                setType={setTypeToBeAdded}
                addFunction={addFunction()}
                getMachinesList={getMachinesList}
                getModelsList={getModelsList}
              />
            </Show>
            <Show
              when={
                createTaskResponse.loading ||
                createMachineResponse.loading ||
                createSourceResponse.loading ||
                createDatasetResponse.loading ||
                createModelResponse.loading
              }
            >
              <div class="ml-5">
                <SpinnerIcon />
                {t("creating", {}, "Creating")}...
              </div>
            </Show>
            <Show
              when={
                createTaskResponse.error ||
                createMachineResponse.error ||
                createSourceResponse.error ||
                createDatasetResponse.error ||
                createModelResponse.error
              }
            >
              <p class="text-sm font-medium text-red-700">
                Something went wrong. Please{" "}
                <a
                  class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                  onClick={() => location.reload()}
                >
                  reload
                </a>{" "}
                page and try again.
              </p>
            </Show>
          </div>
        </main>
      </div>
    </div>
  );
}
