import { useI18n } from "@solid-primitives/i18n";
import {
  Switch,
  For,
  Match,
  createSignal,
  createEffect,
  Show,
  Accessor,
  Setter,
} from "solid-js";
import {
  AnnotationTypes,
  DatasetList,
  DatasetListCompressed,
  EntityTypes,
  SingleDatasetType,
} from "../../../helpers/types";
import { _DeleteDataset, _UpdateDataset } from "../../../helpers/mutations";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import { CopyIcon, SpinnerIcon } from "../../../components/Icons";
import ConfigDatasetOverview from "./ConfigDatasetOverview";
import DeleteConfirmationBox from "../../../components/DeleteConfirmationBox";

const _emptyDataset: SingleDatasetType = {
  annotationType: AnnotationTypes.SingleLabel,
  classes: {
    items: [],
  },
  createdAt: "",
  id: "",
  models: {
    items: [],
  },
  name: "",
  updatedAt: new Date(),
  images: {
    items: [],
  },
};

interface DatasetListProps {
  getDataSetsList: Accessor<DatasetListCompressed | undefined>;
  setDataSetsList: Setter<DatasetListCompressed | undefined>;
  refetchDatasets: (
    info?: unknown,
  ) => DatasetList | Promise<DatasetList | undefined> | null | undefined;
  client: GraphQLClientQuery;
}

export default function DatasetOverview(props: DatasetListProps) {
  const [t] = useI18n();

  const [getSingleDataset, setSingleDataset] =
    createSignal<SingleDatasetType>(_emptyDataset);

  //For confirmation boxes
  const [showConfigDataset, setShowConfigDataset] = createSignal(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    createSignal(false);
  const [showErrorMessage, setShowErrorMessage] = createSignal(false);

  //For handling changes
  const [changesMade, setChangesMade] = createSignal<boolean>(false);
  const [datasetToDeleteId, setDatasetToDeleteId] = createSignal<string>("");
  const [datasetToDeleteName, setDatasetToDeleteName] =
    createSignal<string>("");

  //Set to false as not to fire on mount
  const [deleteQueryInput, setDeleteQueryInput] = createSignal<
    boolean | Object
  >(false);

  const [deleteDatasetResponse] = props.client(
    _DeleteDataset,
    deleteQueryInput,
  );

  //Subscribes to changes
  createEffect(() => {
    if (
      !deleteDatasetResponse.loading &&
      !deleteDatasetResponse.error &&
      deleteDatasetResponse()
    ) {
      console.log("Delete successful. Updating list");
      props.refetchDatasets();
      setShowErrorMessage(false);
    }
    if (deleteDatasetResponse.error) {
      console.log(deleteDatasetResponse.error);
      setShowErrorMessage(true);
    }
  });

  //Handlers and functions
  const configHandler = (dataset: SingleDatasetType) => {
    setSingleDataset(dataset);
    setShowConfigDataset(true);
  };

  const deleteDatasetHandler = (input: { id: string; name: string }) => {
    setDatasetToDeleteId(input.id);
    setDatasetToDeleteName(input.name);
    setShowDeleteConfirmationBox(true);
  };

  const deleteDatasetFunction = (id: string) => {
    const input = { input: { id: id } };
    setDeleteQueryInput(input);
    window.location.href = "#datasetoverview";
  };

  const sortedDatasetList = () => {
    return props.getDataSetsList()?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <>
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="shadow-sm ring-1 ring-black ring-opacity-5">
          <table
            class="min-w-full border-separate"
            style={{
              "border-spacing": "0",
            }}
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  {t("name", {}, "Name")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("lastUpdated", {}, "Last updated")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("id", {}, "ID")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
              </tr>
            </thead>
            <tbody class="bg-white">
              <Switch
                fallback={
                  <For each={sortedDatasetList()}>
                    {(dataset) => (
                      <tr>
                        <td class="whitespace-nowrap w-3/12 border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {dataset.name}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {new Date(dataset.updatedAt).toLocaleDateString(
                            "sv-SE",
                          )}{" "}
                          {new Date(dataset.updatedAt).toLocaleTimeString(
                            "sv-SE",
                          )}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {dataset.id}{" "}
                          <a
                            onClick={() =>
                              navigator.clipboard.writeText(dataset.id)
                            }
                            class="cursor-pointer active:animate-ping text-black active:text-green-400 m-1"
                          >
                            <CopyIcon />
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <a
                            href="#singledatasetoverview"
                            onClick={() => {
                              setShowErrorMessage(false);
                              configHandler(dataset);
                            }}
                            class="text-indigo-600 hover:text-indigo-900"
                          >
                            {t("config", {}, "Config")}
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8 cursor-pointer">
                          <a
                            onClick={() =>
                              deleteDatasetHandler({
                                id: dataset.id,
                                name: dataset.name,
                              })
                            }
                            class="text-red-600 hover:text-red-900"
                          >
                            {t("delete", {}, "Delete")}
                          </a>
                        </td>
                      </tr>
                    )}
                  </For>
                }
              >
                <Match when={props.getDataSetsList()?.length === 0}>
                  <tr>
                    <td colspan="6">
                      <div class="mx-auto my-2 py-1 rounded-full text-center">
                        No current datasets
                      </div>
                    </td>
                  </tr>
                </Match>
              </Switch>
            </tbody>
          </table>
        </div>
      </div>
      <Show when={showErrorMessage()}>
        <p class="block text-sm font-medium text-red-700 m-2">
          *Something went wrong, could not delete. Make sure{" "}
          {datasetToDeleteName()} is not used by a Model before making another
          attempt.
        </p>
      </Show>
      <Show when={deleteDatasetResponse.loading}>
        <div class="m-5">
          <SpinnerIcon />
          {t("deleting", {}, "Deleting")}...
        </div>
      </Show>
      <Show when={showConfigDataset() && getSingleDataset().id !== ""}>
        <ConfigDatasetOverview
          getSingleDataset={getSingleDataset}
          setSingleDataset={setSingleDataset}
          refetchDatasets={props.refetchDatasets}
          changesMade={changesMade}
          setChangesMade={setChangesMade}
          showOverview={setShowConfigDataset}
          client={props.client}
        />
      </Show>
      <Show when={showDeleteConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteConfirmationBox}
          deleteFunction={deleteDatasetFunction}
          nameToDelete={datasetToDeleteName()}
          idToDelete={datasetToDeleteId()}
          type={EntityTypes.dataset}
        />
      </Show>
    </>
  );
}
