import { Auth } from "aws-amplify";
import { useNavigate } from "@solidjs/router";
import { onMount, useContext } from "solid-js";
import { UserContext } from "../../components/UserContext";

export default function SignOutPage() {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    await user?.refetchUserData();
    navigate("/", { replace: true });
  }

  onMount(() => {
    signOut();
  });

  return <></>;
}
