import { onMount } from "solid-js";

interface Props {
  reset: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

function OuterErrorHandler(props: Props) {
  onMount(() => {
    console.error(props.error);
  });

  return (
    <>
      <div class="mx-auto my-auto bg-white rounded-md p-5">
        <h1 class="text-2xl text-red-500 w-fit mx-auto mb-2">Error</h1>
        <div class="border-t-2 py-3 text-gray-600">{props.error.toString()}</div>
        <button
          class="nl-button nl-button--base mx-auto mt-1 block"
          onClick={props.reset} /* eslint-disable-line solid/reactivity */
        >
          Reset
        </button>
      </div>
    </>
  );
}

export default OuterErrorHandler;
