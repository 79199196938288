import {
  createEffect,
  createSignal,
  Match,
  Show,
  Suspense,
  Switch,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";
import { useParams } from "@solidjs/router";
import { useI18n } from "@solid-primitives/i18n";
import { Icon } from "solid-heroicons";
import Carousel from "../../components/Carousel";
import AnnotationComponent from "../Inspection/components/Annotation";
import { flag, tag } from "solid-heroicons/solid";
import { createGraphQLClient } from "../../helpers/graphql";
import {
  AnnotationOriginStatus,
  AnnotationTypes,
  EntityTypes,
  GetDatasetType,
  ImageContainer,
} from "../../helpers/types";
import { UserContext } from "../../components/UserContext";
import FilledBox from "./components/FilledBox";
import {
  _GetDatasetQuery,
  _GetImageContainerQuery,
} from "../../helpers/queries";
import { CopyIcon, SpinnerIcon } from "../../components/Icons";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox";
import { _DeleteImageContainer } from "../../helpers/mutations";

const MAGNIFIER_SIZE = 256;

function FlagIcon() {
  return <Icon aria-hidden="true" class="text-gray-300 h-8 w-8" path={flag} />;
}

function TagIcon() {
  return <Icon class="h-4 w-4" path={tag} />;
}

export default function AnnotateDatasetPage() {
  const params = useParams();
  const [t] = useI18n();
  const user = useContext(UserContext);
  const client = createGraphQLClient(user?.accessToken);
  let magnifierRef!: HTMLDivElement;
  let imageRef!: HTMLImageElement;


  const [dataset, {refetch: refetchDataset}] = client<GetDatasetType>(_GetDatasetQuery, {
    datasetId: params.id,
  });

  const preprocessClasses = (dataset: GetDatasetType | undefined) => {
    if (!dataset) {
      return [];
    }
    const nClasses = dataset.getDataset.classes.items.length;
    const classes: { color: string; id: string; name: string }[] = [];
    for (let i = 0; i < nClasses; i++) {
      const item = dataset.getDataset.classes.items[i];
      classes.push({
        color: item.color,
        id: item.id,
        name: item.name,
      });
    }
    return classes;
  };

  const preprocessClassesMap = (dataset: GetDatasetType | undefined) => {
    if (!dataset) {
      return {};
    }
    const nClasses = dataset.getDataset.classes.items.length;
    const classes: { [name: string]: string } = {};
    for (let i = 0; i < nClasses; i++) {
      classes[dataset.getDataset.classes.items[i].name] =
        dataset.getDataset.classes.items[i].color;
    }
    return classes;
  };

  const [currentImageId, setCurrentImageId] = createSignal<string>();
  const [imageContainers, setImageContainers] = createStore<ImageContainer[]>(
    [],
  );
  const [imageContainer, { refetch: refetchImageContainer }] = client<{
    getImageContainer: ImageContainer;
  }>(_GetImageContainerQuery, () => {
    console.log("currentImageId", currentImageId());
    return currentImageId() ? { imageContainerId: currentImageId() } : false;
  });

  const [showErrorMessage, setShowErrorMessage] = createSignal(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    createSignal(false);

  const [deleteQueryInput, setDeleteQueryInput] = createSignal<
    boolean | Object
  >(false);

  const [deleteImageContainerResponse] = client(
    _DeleteImageContainer,
    deleteQueryInput,
  );

  createEffect(() => {
    if (
      !deleteImageContainerResponse.loading &&
      !deleteImageContainerResponse.error &&
      deleteImageContainerResponse()
    ) {
      console.log("Delete successful. Updating list");
      refetchDataset();
      setShowErrorMessage(false);
    }
    if (deleteImageContainerResponse.error) {
      console.log(deleteImageContainerResponse.error);
      setShowErrorMessage(true);
    }
  });

  createEffect(() => {
    if (dataset.loading || dataset.error) {
      setCurrentImageId();
      return;
    }

    const items = dataset()?.getDataset.images.items;
    if (!items || items.length < 1) {
      setCurrentImageId();
      return;
    }

    items.sort(
      (x1, x2) =>
        Number(new Date(x2.createdAt)) - Number(new Date(x1.createdAt)),
    );
    setImageContainers(items);

    console.log("Setting id to", items[0].id);
    setCurrentImageId(items[0].id);
  });

  createEffect(() => {
    if (
      imageContainer.loading ||
      imageContainer.error ||
      dataset.loading ||
      dataset.error
    ) {
      return;
    }

    const ic = imageContainer()?.getImageContainer;
    if (!ic) {
      // setCurrentClass();
      // setCurrentAnnotationId();
      return;
    }
    console.log("effect", imageContainer());

    setImageContainers((c: ImageContainer) => c.id === ic.id, ic);
  });

  createEffect(() => {
    console.log(imageContainers);
  });

  const carouselSelectImage = (id: string) => {
    console.log(id);
    setCurrentImageId(id);
  };

  const leaveImage = () => {
    magnifierRef.style.display = "none";
  };

  const enterImage = () => {
    magnifierRef.style.display = "block";
  };

  const hoverImage = (e: any) => {
    const sz = Math.round(MAGNIFIER_SIZE / 2);
    const x = e.offsetX;
    const y = e.offsetY;
    const xpx = Math.round((x / imageRef.width) * imageRef.naturalWidth * 1.5);
    const ypx = Math.round(
      (y / imageRef.height) * imageRef.naturalHeight * 1.5,
    );

    magnifierRef.style.backgroundPositionX = `${-xpx + sz}px`;
    magnifierRef.style.backgroundPositionY = `${-ypx + sz}px`;
    magnifierRef.style.left = `${e.pageX - sz}px`;
    magnifierRef.style.top = `${e.pageY - sz}px`;
    magnifierRef.style.backgroundSize = `${imageRef.naturalWidth * 1.5}px ${
      imageRef.naturalHeight * 1.5
    }px`;
  };

  const deleteImageContainerFunction = (id: string) => {
    const input = { input: { id: id } };
    setDeleteQueryInput(input);
  };

  return (
    <main class="flex flex-col grow bg-gray-500">
      <div class="flex flex-row grow justify-end">
        <Suspense>
          <div
            class="absolute bg-no-repeat border-4 border-black cursor-none h-64 hidden pointer-events-none rounded-xl w-64 z-50"
            id="magnifier"
            ref={magnifierRef}
            style={{
              "background-image":
                "url(" +
                (imageContainer?.()?.getImageContainer?.largeURL
                  ? imageContainer?.()?.getImageContainer.largeURL
                  : "") +
                ")",
              "background-size": "200% 200%",
            }}
          />
          <div class="border-gray-400 border-opacity-20 border-r-2 flex h-full relative w-full">
            <img
              class="absolute h-full right-0 top-0 w-auto"
              onMouseMove={hoverImage}
              onMouseEnter={enterImage}
              onMouseLeave={leaveImage}
              ref={imageRef}
              src={
                imageContainer?.()?.getImageContainer?.largeURL
                  ? imageContainer?.()?.getImageContainer.largeURL
                  : ""
              }
            />
          </div>
        </Suspense>
        <div class="flex w-[28rem] flex-col shrink-0 px-3">
          <div class="flex flex-row mt-4 items-baseline align-middle">
            <h3 class="text-sm uppercase font-black text-nl-gray-dark-300">
              {t("annotationTask", {}, "Annotation Task")}:
            </h3>
            <div class="text-xl text-white ml-2">
              <Suspense
                fallback={
                  <div>
                    <SpinnerIcon />
                    {t("loading", {}, "Loading")}...
                  </div>
                }
              >
                {dataset?.()?.getDataset.name ?? "N/A"}
              </Suspense>
            </div>
          </div>
          <Show
            when={
              !imageContainer.loading &&
              !imageContainer.error &&
              imageContainer() !== undefined
            }
          >
            <div class="border-b-2 border-gray-400 pt-1 pb-3">
              <div class="flex flex-row items-baseline">
                <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                  Image:
                </h4>
                <p class="my-1 ml-2 text-white">
                  {imageContainer()?.getImageContainer.name}
                </p>
              </div>
              <div class="flex flex-row items-baseline">
                <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                  ID:
                </h4>
                <p class="my-1 ml-2 text-white">
                  {imageContainer()?.getImageContainer.id}{" "}
                  <a
                    onClick={() =>
                      navigator.clipboard.writeText(
                        imageContainer()?.getImageContainer.id ||
                          "ID not found",
                      )
                    }
                    class="cursor-pointer active:animate-ping text-gray-400 active:text-gray-800 m-1"
                  >
                    <CopyIcon />
                  </a>
                </p>
              </div>
              <div class="flex flex-row items-baseline">
                <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                  Created at:
                </h4>
                <p class="my-1 ml-2 text-white">
                  {new Date(
                    imageContainer()?.getImageContainer.createdAt || "",
                  ).toLocaleDateString("se-SV")}{" "}
                  |
                  {new Date(
                    imageContainer()?.getImageContainer.createdAt || "",
                  ).toLocaleTimeString("se-SV")}
                </p>
              </div>
              <div class="my-1 flex flex-row items-baseline justify-between">
                <div class="flex flex-row items-baseline">
                  <h4 class="text-xs uppercase font-black text-nl-gray-dark-300">
                    Source:
                  </h4>
                  <p class="ml-2 text-white">
                    {imageContainer()?.getImageContainer.source?.name || ""}
                  </p>
                </div>
                <div class="mr-3">
                  <a
                    class="nl-button nl-button--xs bg-nl-red-800 opacity-70 hover:bg-nl-red-900 hover:opacity-90 cursor-pointer"
                    onClick={() => setShowDeleteConfirmationBox(true)}
                  >
                    Delete
                  </a>
                </div>
              </div>
              <Show when={showErrorMessage()}>
                <p class="block text-sm font-medium text-red-700 m-2">
                  *Something went wrong, could not delete.
                </p>
              </Show>
            </div>
          </Show>

          <Suspense>
            <Show
              when={
                imageContainer?.()?.getImageContainer?.annotations?.items ?? []
              }
            >
              <div class="mt-2">
                <Switch>
                  <Match
                    when={
                      dataset?.()?.getDataset.annotationType ===
                      AnnotationTypes.Measurement
                    }
                  >
                    <div class="text-md text-white uppercase">
                      <span class="text-sm font-semibold mr-2 text-nl-gray-dark-200">
                        Fill level:
                      </span>
                      {(imageContainer?.()?.getImageContainer?.annotations
                        ?.items?.[0].annotationData.measurement?.value ?? 0.1) *
                        100}{" "}
                      %
                    </div>
                    <FilledBox
                      levels={JSON.parse(
                        imageContainer?.()?.getImageContainer?.annotations
                          ?.items?.[0].annotationData.measurement
                          ?.extraOutputData ?? "{}",
                      )}
                    />
                  </Match>
                  <Match
                    when={
                      dataset?.()?.getDataset.annotationType ===
                      AnnotationTypes.SingleLabel
                    }
                  >
                    {/* Only show AI annotation if lacking human annotation */}
                    <AnnotationComponent
                      hasAiAnnotation={(
                        imageContainer?.()?.getImageContainer?.annotations
                          ?.items ?? []
                      ).some(
                        (e) =>
                          e.originStatus !==
                          AnnotationOriginStatus.HumanGenerated,
                      )}
                      hasHumanAnnotation={(
                        imageContainer?.()?.getImageContainer?.annotations
                          ?.items ?? []
                      ).some(
                        (e) =>
                          e.originStatus ===
                          AnnotationOriginStatus.HumanGenerated,
                      )}
                      annotations={
                        imageContainer?.()?.getImageContainer?.annotations
                          ?.items ?? []
                      }
                      classes={preprocessClasses(dataset?.())}
                      containerId={imageContainer?.()?.getImageContainer.id}
                      query={client}
                      refetchImageContainer={refetchImageContainer}
                    />
                  </Match>
                  {/* What about BoxAnnotation? */}
                </Switch>
              </div>
            </Show>
          </Suspense>
        </div>
      </div>
      <div class="h-32">
        <Suspense
          fallback={
            <div>
              <SpinnerIcon />
              {t("loading", {}, "Loading")}...
            </div>
          }
        >
          <Carousel
            classes={preprocessClassesMap(dataset?.())}
            currentImageId={currentImageId()}
            images={imageContainers ?? []}
            imageSelectionCallback={carouselSelectImage}
          />
        </Suspense>
      </div>
      <Show when={showDeleteConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteConfirmationBox}
          deleteFunction={deleteImageContainerFunction}
          nameToDelete={imageContainer()?.getImageContainer.name || ""}
          idToDelete={imageContainer()?.getImageContainer.id || ""}
          type={EntityTypes.default}
        />
      </Show>
    </main>
  );
}
