import { type JSX } from "solid-js";
import { A } from "@solidjs/router";
import NeurolearnLogoWhite from "../svgs/nl-logo-white.svg?component-solid";

export function Footer(): JSX.Element {
  return (
    <footer class="border-t border-gray-400 bg-gray-300">
      <div class="flex h-16 justify-end items-center mx-8">
        <A href="/">
          <NeurolearnLogoWhite class="w-[160px]" />
        </A>
      </div>
    </footer>
  );
}
