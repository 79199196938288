import { GraphQLClientQuery } from "@solid-primitives/graphql";
import {
  EntityTypes,
  MachineListCompressed,
  ModelsListCompressed,
  SingleTaskType,
  TasksListCompressed,
  TasksListExtended,
} from "../../../helpers/types";
import {
  Accessor,
  For,
  Match,
  Setter,
  Show,
  Switch,
  createEffect,
  createSignal,
} from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import DeleteConfirmationBox from "../../../components/DeleteConfirmationBox";
import { CopyIcon, SpinnerIcon } from "../../../components/Icons";
import { _DeleteDataset, _DeleteTask } from "../../../helpers/mutations";
import ConfigTaskOverview from "./ConfigTaskOverview";

export const _emptyTask: SingleTaskType = {
  id: "",
  MachineID: "",
  ModelID: "",
  createdAt: "",
  description: {
    description: "",
  },
  imageKey: "",
  updatedAt: "",
  url: "",
  machine: {
    id: "",
    name: "",
  },
  model: {
    id: "",
    name: "",
    runtime: "",
    datasets: {
      items: [],
    },
  },
};

interface TasksListProps {
  getTasksList: Accessor<TasksListCompressed | undefined>;
  setTasksList: Setter<TasksListCompressed | undefined>;
  refetchTasks: (
    info?: unknown,
  ) =>
    | TasksListExtended
    | Promise<TasksListExtended | undefined>
    | null
    | undefined;
  getMachinesList: Accessor<MachineListCompressed | undefined>;
  getModelsList: Accessor<ModelsListCompressed | undefined>;
  client: GraphQLClientQuery;
}

export default function TaskOverview(props: TasksListProps) {
  const [t] = useI18n();

  const [getSingleTask, setSingleTask] =
    createSignal<SingleTaskType>(_emptyTask);

  //For confirmationboxes
  const [showConfigTask, setShowConfigTask] = createSignal(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    createSignal(false);

  //For handling changes
  const [taskToDeleteId, setTaskToDeleteId] = createSignal<string>("");
  const [taskToDeleteName, setTaskToDeleteName] = createSignal<string>("");

  //Set to false as not to fire query on mount
  const [deleteQueryInput, setDeleteQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [deleteTaskResponse] = props.client<boolean>(
    _DeleteTask,
    deleteQueryInput,
  );

  //Subsribers to changes
  createEffect(() => {
    if (
      !deleteTaskResponse.loading &&
      !deleteTaskResponse.error &&
      deleteTaskResponse()
    ) {
      console.log("Delete successful. Updating list");
      props.refetchTasks();
    }
  });

  //Handlers and functions
  const configHandler = (task: SingleTaskType) => {
    setSingleTask(task);
    setShowConfigTask(true);
  };

  const deleteTaskHandler = (input: { id: string; name: string }) => {
    setTaskToDeleteId(input.id);
    setTaskToDeleteName(input.name);
    setShowDeleteConfirmationBox(true);
  };

  const deleteTaskFunction = (id: string) => {
    const input = { input: { id: id } };
    setDeleteQueryInput(input);
    window.location.href = "#taskoverview";
  };

  const sortedTaskList = () => {
    return props.getTasksList()?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <>
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="shadow-sm ring-1 ring-black ring-opacity-5">
          <table
            class="min-w-full border-separate"
            style={{
              "border-spacing": "0",
            }}
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  {t("description", {}, "Description")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("lastUpdated", {}, "Last updated")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("id", {}, "ID")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
              </tr>
            </thead>
            <tbody class="bg-white">
              <Switch
                fallback={
                  <For each={sortedTaskList()}>
                    {(task) => (
                      <tr>
                        <td class="w-3/12 border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {task.description.description}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {new Date(task.updatedAt).toLocaleDateString("sv-SE")}{" "}
                          {new Date(task.updatedAt).toLocaleTimeString("sv-SE")}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {task.id}{" "}
                          <a
                            onClick={() =>
                              navigator.clipboard.writeText(task.id)
                            }
                            class="cursor-pointer active:animate-ping text-black active:text-green-400 m-1"
                          >
                            <CopyIcon />
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <a
                            href="#singletaskoverview"
                            onClick={() => configHandler(task)}
                            class="text-indigo-600 hover:text-indigo-900"
                          >
                            {t("config", {}, "Config")}
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8 cursor-pointer">
                          <a
                            onClick={() =>
                              deleteTaskHandler({
                                id: task.id,
                                name: task.description.description,
                              })
                            }
                            class="text-red-600 hover:text-red-900"
                          >
                            {t("delete", {}, "Delete")}
                          </a>
                        </td>
                      </tr>
                    )}
                  </For>
                }
              >
                <Match when={props.getTasksList()?.length === 0}>
                  <tr>
                    <td colspan="6">
                      <div class="mx-auto my-2 py-1 rounded-full text-center">
                        No current datasets
                      </div>
                    </td>
                  </tr>
                </Match>
              </Switch>
            </tbody>
          </table>
        </div>
      </div>
      <Show when={deleteTaskResponse.error}>
        <p class="block text-sm font-medium text-red-700 m-2">
          *Something went wrong, could not delete. Try again or reload page.
        </p>
      </Show>
      <Show when={deleteTaskResponse.loading}>
        <div class="m-5">
          <SpinnerIcon />
          {t("deleting", {}, "Deleting")}...
        </div>
      </Show>
      <Show when={showConfigTask() && getSingleTask().id !== ""}>
        <ConfigTaskOverview
          updatableTask={getSingleTask}
          setUpdatableTask={setSingleTask}
          refetchTasks={props.refetchTasks}
          getMachinesList={props.getMachinesList}
          getModelsList={props.getModelsList}
          showOverview={setShowConfigTask}
          client={props.client}
        />
      </Show>
      <Show when={showDeleteConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteConfirmationBox}
          deleteFunction={deleteTaskFunction}
          nameToDelete={taskToDeleteName()}
          idToDelete={taskToDeleteId()}
          type={EntityTypes.task}
        />
      </Show>
    </>
  );
}
