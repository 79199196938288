//Contains typedefinitions for objects retrieved from DB

export enum AnnotationOriginStatus {
  HumanGenerated = "humanGenerated",
  AiGenerated = "aiGenerated",
  AiGeneratedHumanApproved = "aiGeneratedHumanApproved",
  AiGeneratedHumanRejected = "aiGeneratedHumanRejected",
}

export enum AnnotationValuation {
  Good = "Good",
  Bad = "Bad",
  Neutral = "Neutral",
}

export enum AnnotationTypes {
  Box = "BoxAnnotation",
  SingleLabel = "SingleLabelClassificationAnnotation",
  Measurement = "MeasurementAnnotation",
}

export enum RuntimeTypes {
  lambda = "lambda"
}

export enum ClassLabelColors {
  Green = "green",
  LightGreen = "lightgreen",
  OliveDrab = "olivedrab",
  Gray = "gray",
  DarkGray = "darkgray",
  Brown = "brown",
  Khaki = "khaki",
  Blue = "blue",
  LightBlue = "lightblue",
  Cyan = "cyan",
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
  Purple = "purple",
  Pink = "pink",
  White = "white",
}

export enum EntityTypes {
  default = "default",
  task = "task",
  machine = "machine",
  source = "source",
  dataset = "dataset",
  classLabel = "classLabel",
  model = "model",
  datasetUsedByModel = "datasetUsedByModel"
}

export enum AuthSignInErrorCode {
  USER_NOT_CONFIRMED = "UserNotConfirmedException",
  USER_NOT_FOUND = "UserNotFoundException",
  USER_NOT_AUTHORIZED = "NotAuthorizedException",
  USER_REQUIRES_PASSWORD_RESET = "PasswordResetRequiredException",
}

export enum AuthForgotPasswordErrorCode {
  USER_NOT_FOUND = "UserNotFoundException",
}

export enum AuthForgotPasswordSubmitErrorCode {
  USER_NOT_FOUND = "UserNotFoundException",
  EXPIRED_CODE = "ExpiredCodeException",
  MISMATCHED_CODE = "CodeMismatchException",
}

export type User = {
  accessToken: string;
  email: string | undefined;
  userName: string;
  group: string;
  refetchUserData: any;
};

export type Box = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type Annotation = {
  annotationData: {
    annotationType: AnnotationTypes;
    box: Box;
    measurement: {
      extraOutputData: string;
      value: number;
    };
    singleLabelClassification: {
      label: {
        color: string;
        id: string;
        name: string;
      };
      confidence: number;
    };
  };
  createdAt: string;
  id: string;
  needsReview: boolean;
  originStatus: AnnotationOriginStatus;
  updatedAt: string;
};

export type ImageAnnotationMinimal = {
  listImageAnnotation: {
    items: Array<{
      id: string;
      annotationData: {
        annotationType: string;
        box?: {
          labelId: string;
        } | null;
        singleLabelClassification?: {
          labelId: string;
        } | null;
      };
    }>;
  };
};

export type UpdateImageAnnotationInput = {
  ImageAnnotation_belongsToImageContainerID: string;
  annotationData: {
    annotationType: AnnotationTypes;
    box: Box;
    singleLabelClassification: string;
  };
};
export type ImageContainer = {
  annotationComplete: boolean | undefined;
  annotations: {
    items: Annotation[];
  };
  createdAt: string;
  dataset: DatasetMinimal;
  id: string;
  imageKey: string;
  name: string;
  needsReview: boolean;
  source: {
    id: string;
    name: string;
  };
  updatedAt: string;
  smallURL: string;
  largeURL: string;
  originalURL: string;
};

export type ImageContainerMinimum = {
  id: string;
};

export type DatasetList = {
  listDataset: {
    items: {
      name: string;
      id: string;
      annotationType: AnnotationTypes;
      classes: {
        items: {
          color: string;
          name: string;
          id: string;
          modelClassId: string;
          createdAt: string;
        }[];
      };
      models: {
        items: {
          Model: {
            name: string;
            id: string;
            createdAt: string;
          };
        }[];
      };
      createdAt: string;
      updatedAt: Date;
      images: {
        items: {
          id: string;
        }[];
      };
    }[];
  };
};

export type DatasetListCompressed = {
  name: string;
  id: string;
  annotationType: AnnotationTypes;
  classes: {
    items: {
      color: string;
      name: string;
      id: string;
      modelClassId: string;
      createdAt: string;
    }[];
  };
  models: {
    items: {
      Model: {
        name: string;
        id: string;
        createdAt: string;
      };
    }[];
  };
  createdAt: string;
  updatedAt: Date;
  images: {
    items: {
      id: string;
    }[];
  };
}[];

export type SingleDatasetType = {
  name: string;
  id: string;
  annotationType: AnnotationTypes;
  classes: {
    items: {
      color: string;
      name: string;
      id: string;
      modelClassId: string;
      createdAt: string;
    }[];
  };
  models: {
    items: {
      Model: {
        name: string;
        id: string;
        createdAt: string;
      };
    }[];
  };
  createdAt: string;
  updatedAt: Date;
  images: {
    items: {
      id: string;
    }[];
  };
};

export type DatasetImagesAndAnnotations = {
  getDataset: {
    id: string,
    images: {
      items: {
        id: string;
        url: string;
        imageKey: string;
        annotations: {
          items: {
            annotationData: {
              singleLabelClassification: {
                label: {
                  name: string;
                };
              };
            };
            originStatus: string;
          }[];
        };
        source: {
          id: string;
          name: string;
        };
      }[];
    };
  };
};


export type GetDatasetType = {
  getDataset: {
    annotationType: AnnotationTypes;
    classes: {
      items: {
        color: string;
        id: string;
        name: string;
      }[];
    };
    id: string;
    images: {
      items: ImageContainer[];
    };
    name: string;
  };
};

export type DatasetMinimal = {
  annotationType: AnnotationTypes;
  classes: {
    items: {
      color: string;
      id: string;
      name: string;
    }[];
  };
  id: string;
  name: string;
};

export type SingleDatasetUsedByModelType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  Dataset: {
    id: string;
    name: string;
    updatedAt: string;
    images: {
      items: {
        id: string;
      }[];
    };
  };
};

export type CreateDatasetUsedByModelType = {
  createDatasetsUsedByModel: {
    id: string;
    createdAt: string;
    updatedAt: string;
    Dataset: {
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      images: {
        items: {
          id: string;
        }[];
      };
    };
  };
};

export type SingleClassLabel = {
  color: string;
  name: string;
  id: string;
  modelClassId: string;
};

export type TasksList = {
  listTask: {
    items: {
      createdAt: string;
      description: {
        description: string;
      };
      id: string;
      machine: {
        id: string;
        name: string;
      };
      MachineID: string;
      ModelID: string;
      updatedAt: string;
      url: string | null;
    }[];
  };
};

export type TasksListExtended = {
  listTask: {
    items: {
      id: string;
      MachineID: string;
      ModelID: string;
      createdAt: string;
      description: {
        description: string;
      };
      imageKey: string | null;
      updatedAt: string;
      url: string | null;
      machine: {
        id: string;
        name: string;
      };
      model: {
        id: string;
        name: string;
        runtime: string;
        datasets: {
          items: {
            Dataset: {
              id: string;
              name: string;
              updatedAt: string;
              images: {
                items: {
                  id: string;
                }[];
              };
            };
          }[];
        };
      };
    }[];
  };
};

export type TasksListCompressed = {
  id: string;
  MachineID: string;
  ModelID: string;
  createdAt: string;
  description: {
    description: string;
  };
  imageKey: string | null;
  updatedAt: string;
  url: string | null;
  machine: {
    id: string;
    name: string;
  };
  model: {
    id: string;
    name: string;
    runtime: string;
    datasets: {
      items: {
        Dataset: {
          id: string;
          name: string;
          updatedAt: string;
          images: {
            items: {
              id: string;
            }[];
          };
        };
      }[];
    };
  };
}[];

export type SingleTaskType = {
  id: string;
  MachineID: string;
  ModelID: string;
  createdAt: string;
  description: {
    description: string;
  };
  imageKey: string | null;
  updatedAt: string;
  url: string | null;
  machine: {
    id: string;
    name: string;
  };
  model: {
    id: string;
    name: string;
    runtime: string;
    datasets: {
      items: {
        Dataset: {
          id: string;
          name: string;
          updatedAt: string;
          images: {
            items: {
              id: string;
            }[];
          };
        };
      }[];
    };
  };
};



export type MachineList = {
  listMachine: {
    items: {
      config: string;
      createdAt: string;
      id: string;
      name: string;
      updatedAt: string;
    }[];
  };
};

export type MachineListCompressed = {
  config: string;
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
}[];

export type SingleMachineType = {
  config: string;
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
};

export type MachineMinimalType = {
  id: string;
  name: string;
  config: string;
};

export type SourceList = {
  listSource: {
    items: {
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    }[];
  };
};

export type SourceListCompressed = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}[];

export type ModelsList = {
  listModel: {
    items: {
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      runtime: string;
      datasets: {
        items: {
          id: string;
          createdAt: string;
          updatedAt: string;
          Dataset: {
            id: string;
            name: string;
            createdAt: string;
            updatedAt: string;
            images: {
              items: {
                id: string;
              }[];
            };
          };
        }[];
      };
    }[];
  };
};

export type ModelsListCompressed = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  runtime: string;
  datasets: {
    items: {
      id: string;
      createdAt: string;
      updatedAt: string;
      Dataset: {
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        images: {
          items: {
            id: string;
          }[];
        };
      };
    }[];
  };
}[];

export type SingleModelType = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  runtime: string;
  datasets: {
    items: {
      id: string;
      createdAt: string;
      updatedAt: string;
      Dataset: {
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        images: {
          items: {
            id: string;
          }[];
        };
      };
    }[];
  };
};
