import { createEffect, For, Show, useContext } from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import { gql } from "@solid-primitives/graphql";
import TaskOverview from "./components/TaskOverview";
import { UserContext } from "../../components/UserContext";
import { createGraphQLClient } from "../../helpers/graphql";
import { _ListTasksQuery } from "../../helpers/queries";
import { TasksList } from "../../helpers/types";
import { SpinnerIcon } from "../../components/Icons";

export default function DashboardPage() {
  const [t] = useI18n();
  const user = useContext(UserContext);
  const query = createGraphQLClient(user?.accessToken);

   // async function fetchTasks(token: string, { value, refetching }) {
  //   if (!token) {
  //     return Promise.reject(new Error("No token"));
  //   }

  //   return request(_ListTasksQuery, ...
  //   });
  // }
  // const [tasksList] = createResource(user?.accessToken, fetchTasks);

  const [tasksList, {refetch: refetchTasks}] = query<TasksList>(_ListTasksQuery); 

  createEffect(() => {
    if (!tasksList) {
      return;
    }
    console.log("tasksList", tasksList());
  });

  const sortedMachinesList = () => {
    return tasksList()?.listTask.items?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <div class="h-screen">
      <div class="py-10">
        <header>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {t("dashboard", {}, "Dashboard")}
            </h1>
          </div>
        </header>
        <main>
          <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="px-4 py-8 sm:px-0">
              <Show
                when={
                  !tasksList.loading &&
                  !tasksList.error &&
                  tasksList()?.listTask
                }
              >
                <For each={sortedMachinesList()}>
                  {(task) => (
                    <TaskOverview
                      createdAt={task.createdAt}
                      description={task.description.description}
                      id={task.id}
                      machine={task.machine}
                      modelId={task.ModelID}
                      updatedAt={task.updatedAt}
                      url={task.url || null}
                    />
                  )}
                </For>
              </Show>
              <Show when={tasksList.loading}>
                <SpinnerIcon/>
                {t("loading", {}, "Loading")}...
              </Show>
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </div>
  );
}
