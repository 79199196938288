import {
  Switch,
  For,
  Match,
  createSignal,
  Accessor,
  Setter,
  Show,
  createEffect,
} from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import { t } from "vitest/dist/global-fe52f84b";
import { Backward, CopyIcon, SpinnerIcon } from "../../../components/Icons";
import {
  EntityTypes,
  MachineList,
  MachineListCompressed,
  SingleMachineType,
} from "../../../helpers/types";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import { createStore } from "solid-js/store";
import ConfigMachineOverview from "./ConfigMachineOverview";
import DeleteConfirmationBox from "../../../components/DeleteConfirmationBox";
import { _DeleteMachine, _UpdateMachine } from "../../../helpers/mutations";
import ChangePageConfirmationBox from "../../../components/ChangePageConfirmationBox";
import RevertConfirmationBox from "../../../components/RevertConfirmationBox";

const _emptyMachine: SingleMachineType = {
  config: "",
  createdAt: "",
  id: "",
  name: "",
  updatedAt: "",
};

interface MachineListProps {
  getMachinesList: Accessor<MachineListCompressed | undefined>;
  setMachinesList: Setter<MachineListCompressed | undefined>;
  refetchMachines: (
    info?: unknown,
  ) => MachineList | Promise<MachineList | undefined> | null | undefined;
  originalConfig: Accessor<
    { machineID: string; originalConfig: string } | undefined
  >;
  setOriginalConfig: Setter<
    { machineID: string; originalConfig: string } | undefined
  >;
  client: GraphQLClientQuery;
}

export default function MachineOverview(props: MachineListProps) {
  const [t] = useI18n();

  const [updatableMachine, setUpdatableMachine] =
    createStore<SingleMachineType>(_emptyMachine);
  let singleMachine: SingleMachineType = updatableMachine;
  const [machineId, setMachineId] = createSignal<string>("");

  //For confirmation boxes
  const [showConfigMachine, setShowConfigMachine] =
    createSignal<boolean>(false);
  const [showChangePageConfirmationBox, setShowChangePageConfirmationBox] =
    createSignal<boolean>(false);
  const [showRevertConfirmationBox, setShowRevertConfirmationBox] =
    createSignal(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    createSignal(false);

  //For handling changes
  const [changesMade, setChangesMade] = createSignal<boolean>(false);
  const [machineToDeleteId, setMachineToDeleteId] = createSignal<string>("");
  const [machineToDeleteName, setMachineToDeleteName] =
    createSignal<string>("");

  //Setting query input as false as not to fire query on mount
  const [undoQueryInput, setUndoQueryInput] = createSignal<object | boolean>(
    false,
  );
  const [undoChangesResponse] = props.client(_UpdateMachine, undoQueryInput);

  const [deleteQueryInput, setDeleteQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [deleteMachineResponse] = props.client<boolean>(
    _DeleteMachine,
    deleteQueryInput,
  );

  //Subscribers to changes

  createEffect(() => {
    if (
      !undoChangesResponse.loading &&
      !undoChangesResponse.error &&
      undoChangesResponse()
    ) {
      console.log("Changes were successfully reverted. Refetching machines.");
      props.setOriginalConfig(undefined);
      window.location.href = "#machineoverview";
      props.refetchMachines();
    }
  });

  createEffect(() => {
    if (
      !deleteMachineResponse.loading &&
      !deleteMachineResponse.error &&
      deleteMachineResponse()
    ) {
      console.log("Delete successful. Updating list");
      window.location.href = "#machineoverview";
      props.refetchMachines();
    }
  });

  //Handlers and functions
  const changePage = () => {
    setUpdatableMachine(singleMachine);
    setMachineId(singleMachine.id);
    setChangesMade(false);
  };

  const configHandler = () => {
    if (changesMade()) {
      setShowChangePageConfirmationBox(true);
      return;
    }
    changePage();
    setShowConfigMachine(true);
  };

  const revertFunction = () => {
    const updateMachineId = props.originalConfig()?.machineID;
    const input = { config: props.originalConfig()?.originalConfig };
    setUndoQueryInput({ updateMachineId, input });
  };

  const deleteMachineHandler = (input: { id: string; name: string }) => {
    setMachineToDeleteId(input.id);
    setMachineToDeleteName(input.name);
    setShowDeleteConfirmationBox(true);
  };

  const deleteMachineFunction = (id: string) => {
    const input = { input: { id: id } };
    setDeleteQueryInput(input);
  };

  const sortedMachinesList = () => {
    return props.getMachinesList()?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <>
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="shadow-sm ring-1 ring-black ring-opacity-5">
          <table
            class="min-w-full border-separate"
            style={{
              "border-spacing": "0",
            }}
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  {t("name", {}, "Name")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("created at", {}, "Created at")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("id", {}, "ID")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
              </tr>
            </thead>
            <tbody class="bg-white">
              <Switch
                fallback={
                  <For each={sortedMachinesList()}>
                    {(machine) => (
                      <tr>
                        <td class="whitespace-nowrap w-3/12 border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {machine.name}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {new Date(machine.createdAt).toLocaleDateString(
                            "sv-SE",
                          )}{" "}
                          {new Date(machine.updatedAt).toLocaleTimeString(
                            "sv-SE",
                          )}
                        </td>
                        <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                          {machine.id}
                          <a
                            onClick={() =>
                              navigator.clipboard.writeText(machine.id)
                            }
                            class="cursor-pointer active:animate-ping text-black active:text-green-400 m-1"
                          >
                            <CopyIcon />
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <Show
                            when={
                              props.originalConfig()?.machineID === machine.id
                            }
                          >
                            <a
                              onClick={() => {
                                singleMachine = machine;
                                setShowRevertConfirmationBox(true);
                              }}
                              class="text-nl-yellow-600 hover:text-nl-yellow-900"
                            >
                              <Backward />
                            </a>
                          </Show>
                          <a
                            href="#singlemachineoverview"
                            onClick={() => {
                              singleMachine = machine;
                              configHandler();
                            }}
                            class="text-indigo-600 hover:text-indigo-900 "
                          >
                            {t("config", {}, "Config")}
                          </a>
                        </td>
                        <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8 cursor-pointer">
                          <a
                            onClick={() =>
                              deleteMachineHandler({
                                id: machine.id,
                                name: machine.name,
                              })
                            }
                            class="text-red-600 hover:text-red-900"
                          >
                            {t("delete", {}, "Delete")}
                          </a>
                        </td>
                      </tr>
                    )}
                  </For>
                }
              >
                <Match when={props.getMachinesList()?.length === 0}>
                  <tr>
                    <td colspan="6">
                      <div class="mx-auto my-2 py-1 rounded-full text-center">
                        No current machines
                      </div>
                    </td>
                  </tr>
                </Match>
              </Switch>
            </tbody>
          </table>
        </div>
      </div>
      <Show when={deleteMachineResponse.error}>
        <p class="block text-sm font-medium text-red-700 m-2">
          *Something went wrong, could not delete. Try again or reload page.
        </p>
      </Show>
      <Show when={deleteMachineResponse.loading}>
        <div class="m-5">
          <SpinnerIcon />
          {t("deleting", {}, "Deleting")}...
        </div>
      </Show>
      <Show when={showConfigMachine() && updatableMachine.id !== ""}>
        <ConfigMachineOverview
          updatableMachine={updatableMachine}
          setUpdatableMachine={setUpdatableMachine}
          machineId={machineId}
          refetchMachines={props.refetchMachines}
          showOverview={setShowConfigMachine}
          changesMade={changesMade}
          setChangesMade={setChangesMade}
          originalConfig={props.originalConfig}
          setOriginalConfig={props.setOriginalConfig}
          client={props.client}
        />
      </Show>
      <Show when={showRevertConfirmationBox()}>
        <RevertConfirmationBox
          showDialog={setShowRevertConfirmationBox}
          revertFunction={revertFunction}
          nameToRevert={singleMachine.name}
        />{" "}
      </Show>
      <Show when={showDeleteConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteConfirmationBox}
          deleteFunction={deleteMachineFunction}
          nameToDelete={machineToDeleteName()}
          idToDelete={machineToDeleteId()}
          type={EntityTypes.machine}
        />
      </Show>
      <Show when={showChangePageConfirmationBox()}>
        <ChangePageConfirmationBox
          showDialog={setShowChangePageConfirmationBox}
          changePage={changePage}
        ></ChangePageConfirmationBox>
      </Show>
    </>
  );
}
