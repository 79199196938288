export const validators = {
  email: (value: string) => {
    if (value.length === 0) {
      return null;
    } else if (value.includes("@")) {
      const parts = value.split("@");
      if (parts[0].length < 1 || parts[1].length < 3) {
        return { invalidUsernameOrEmail: "Invalid email address." };
      } else {
        return null;
      }
    } else {
      return { invalidUsernameOrEmail: "Invalid email address." };
    }
  },
  password: (value: string) =>
    value.length === 0 ||
    // Must be long enough.
    (value.length >= 8 &&
      // Must contain digit.
      value.match(/\d/) &&
      // Must contain upper case character.
      value.match(/[A-Z]/) &&
      // Must contain symbol.
      value.match(/[^A-Za-z0-9]/))
      ? null
      : {
          invalidPassword:
            "Password must be at least 8 characters long and contain a symbol, a digit and an upper case character.",
        },
  required: (value: string) =>
    value.length === 0 ? { isMissing: "Required field." } : null,
  username: (value: string) =>
    value.length <= 128 ? null : { invalidEmail: "Invalid username." },
  usernameOrEmail: (value: string) => {
    if (value.length === 0) {
      return null;
    } else if (value.includes("@")) {
      const parts = value.split("@");
      if (parts[0].length < 1 || parts[1].length < 3) {
        return { invalidUsernameOrEmail: "Invalid email address." };
      } else {
        return null;
      }
    } else if (value.length > 128) {
      return { invalidUsernameOrEmail: "Invalid username." };
    } else {
      return null;
    }
  },
};
