import {
  Accessor,
  For,
  Match,
  Show,
  Switch,
  createEffect,
  createSignal,
} from "solid-js";
import {
  ClassLabelColors,
  DatasetList,
  EntityTypes,
  ImageAnnotationMinimal,
  SingleClassLabel,
} from "../../../helpers/types";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import {
  _CreateClassLabel,
  _DeleteClassLabel,
  _UpdateClassLabel,
  _UpdateDataset,
} from "../../../helpers/mutations";
import DeleteConfirmationBox from "../../../components/DeleteConfirmationBox";
import UpdateConfirmationBox from "../../../components/UpdateConfirmationBox";
import { CopyIcon, SpinnerIcon } from "../../../components/Icons";
import CreateConfirmationBox from "../../../components/CreateConfirmationBox";
import { _ListImageAnnotationQuery } from "../../../helpers/queries";

interface ClassLabelOverviewProps {
  getClassLabel: Accessor<SingleClassLabel>;
  datasetId: string;
  refetchDatasets: (
    info?: unknown,
  ) => DatasetList | Promise<DatasetList | undefined> | null | undefined;
  closeOverview: () => void;
  client: GraphQLClientQuery;
}

export default function ClassLabelOverview(props: ClassLabelOverviewProps) {
  var nameInput!: HTMLInputElement;
  var colorSelector!: HTMLSelectElement;
  var modelIdSelector!: HTMLSelectElement;

  const modelClassIds = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [imageAnnotationsList, { refetch: refetchImageAnnotations }] =
    props.client<ImageAnnotationMinimal>(_ListImageAnnotationQuery);

  //For confirmationboxes
  const [showCreateConfirmationBox, setShowCreateConfirmationBox] =
    createSignal(false);
  const [showUpdateConfirmationBox, setShowUpdateConfirmationBox] =
    createSignal(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    createSignal(false);

  //For keeping track of changes
  const [nameIsChanged, setNameIsChanged] = createSignal<boolean>(false);
  const [colorIsChanged, setColorIsChanged] = createSignal<boolean>(false);
  const [modelClassIdIsChanged, setModelClassIdIsChanged] =
    createSignal<boolean>(false);
  const [changesMade, setChangesMade] = createSignal<boolean>(false);

  //Validators
  const [allFieldsRequired, setAllFieldsRequired] =
    createSignal<boolean>(false);
  const [createErrorMessage, setCreateErrorMessage] =
    createSignal<boolean>(false);

  const [cantDeleteMessage, setCantDeleteMessage] =
    createSignal<boolean>(false);

  //Set to false as not to fire on mount
  const [createQueryInput, setCreateQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [updateQueryInput, setUpdateQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [deleteQueryInput, setDeleteQueryInput] = createSignal<
    boolean | Object
  >(false);

  const [createClassLabelResponse] = props.client(
    _CreateClassLabel,
    createQueryInput,
  );
  const [updateClassLabelResponse] = props.client(
    _UpdateClassLabel,
    updateQueryInput,
  );
  const [deleteClassLabelResponse] = props.client<boolean>(
    _DeleteClassLabel,
    deleteQueryInput,
  );

  //Subscribers to change
  createEffect(() => {
    if (
      !createClassLabelResponse.loading &&
      !createClassLabelResponse.error &&
      createClassLabelResponse()
    ) {
      console.log(createClassLabelResponse());
      console.log("Label successfully created");
      setAllFieldsRequired(false);
      props.refetchDatasets();
    }
  });

  createEffect(() => {
    if (
      !updateClassLabelResponse.loading &&
      !updateClassLabelResponse.error &&
      updateClassLabelResponse()
    ) {
      console.log(updateClassLabelResponse());
      console.log("Successful update");
      setChangesMade(false);
      props.refetchDatasets();
    }
  });

  createEffect(() => {
    if (
      !deleteClassLabelResponse.loading &&
      !deleteClassLabelResponse.error &&
      deleteClassLabelResponse()
    ) {
      console.log(deleteClassLabelResponse());
      console.log("Successful delete");
      refetchImageAnnotations();
      props.refetchDatasets();
    }
  });

  createEffect(() => {
    if (nameIsChanged() || colorIsChanged() || modelClassIdIsChanged()) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  });

  createEffect(() => {
    if (nameIsChanged() && colorIsChanged() && modelClassIdIsChanged()) {
      setAllFieldsRequired(true);
    } else {
      setAllFieldsRequired(false);
    }
  });

  createEffect(() => {
    console.log(props.getClassLabel());
    if (props.getClassLabel().id === "") {
      colorSelector.selectedIndex = 0;
      modelIdSelector.selectedIndex = 0;
    }
  });

  createEffect(() => {
    if (imageAnnotationsList === undefined) {
      return;
    }
    console.log("ImageAnnotations: ", imageAnnotationsList());
    console.log(props.getClassLabel().id);
  });

  //Handlers and functions
  const nameInputHandler = () => {
    if (
      nameInput.value === "" ||
      nameInput.value === props.getClassLabel().name
    ) {
      setNameIsChanged(false);
    } else {
      setNameIsChanged(true);
    }
  };

  const selectColorHandler = () => {
    if (
      colorSelector.selectedIndex === 0 ||
      colorSelector.value === props.getClassLabel().color
    ) {
      setColorIsChanged(false);
    } else {
      setColorIsChanged(true);
    }
  };

  const selectModelClassIdHandler = () => {
    if (
      modelIdSelector.selectedIndex === 0 ||
      modelIdSelector.value === props.getClassLabel().id
    ) {
      setModelClassIdIsChanged(false);
    } else {
      setModelClassIdIsChanged(true);
    }
  };

  const createClassLabelFunction = () => {
    setCreateErrorMessage(false);
    console.log("Creating");
    const input = {
      input: {
        ClassLabel_belongsToDatasetID: props.datasetId,
        color: colorSelector.value,
        modelClassId: parseInt(modelIdSelector.value),
        name: nameInput.value,
      },
    };

    setCreateQueryInput(input);
    window.location.href = "#datasetoverview";
  };

  const updateClassLabelFunction = () => {
    var input = {
      updateClassLabelId: props.getClassLabel().id,
      input: {},
    };

    if (nameIsChanged()) {
      input.input = { name: nameInput.value };
    }
    if (colorIsChanged()) {
      input.input = { ...input.input, color: colorSelector.value };
    }
    if (modelClassIdIsChanged()) {
      input.input = {
        ...input.input,
        modelClassId: parseInt(modelIdSelector.value),
      };
    }

    setUpdateQueryInput(input);
    window.location.href = "#datasetoverview";
  };

  const deleteClassLabel = () => {
    const annotations = imageAnnotationsList()?.listImageAnnotation.items;

    const hasReference = annotations?.some((annotation) => {
      return (
        annotation.annotationData.singleLabelClassification?.labelId ===
        props.getClassLabel().id
      );
    });

    if (hasReference) {
      setCantDeleteMessage(true);
      return;
    }

    setCantDeleteMessage(false);
    setShowDeleteConfirmationBox(true);
  };

  const deleteClassLabelFunction = (id: string) => {
    const input = { input: { id: id } };
    setDeleteQueryInput(input);
    window.location.href = "#datasetoverview";
  };

  return (
    <div class="my-10 ml-5 mr-14">
      <Switch
        fallback={
          <h3 class="font-bold leading-tight tracking-tight text-gray-900 mb-2 py-1">
            Edit classlabel
          </h3>
        }
      >
        <Match when={props.getClassLabel().id === ""}>
          <h3 class="font-bold leading-tight tracking-tight text-gray-900 mb-2 py-1">
            New classlabel
          </h3>
        </Match>
      </Switch>

      <div class="rounded-md border border-gray-300 flex flex-col justify-evenly p-3">
        <div>
          <label
            for="classLabelName"
            class="block text-sm font-medium text-gray-700 m-2"
          >
            Name
          </label>
          <input
            class="block w-11/12 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
            id="classLabelName"
            ref={nameInput}
            type="text"
            placeholder={props.getClassLabel().name}
            onInput={() => nameInputHandler()}
          />

          <label
            for="classLabelColor"
            class="block text-sm font-medium text-gray-700 m-2"
          >
            Color
          </label>
          <select
            class="block w-11/12 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
            id="classLabelColor"
            ref={colorSelector}
            onChange={() => selectColorHandler()}
          >
            <Show when={props.getClassLabel().id !== ""}>
              <option class="text-gray-500" value={props.getClassLabel().color}>
                {props.getClassLabel().color}*
              </option>
            </Show>
            <Show when={props.getClassLabel().id === ""}>
              <option />
            </Show>
            <For each={Object.values(ClassLabelColors)}>
              {(color) => (
                <Show when={color !== props.getClassLabel().color}>
                  <option value={color}>{color}</option>
                </Show>
              )}
            </For>
          </select>
          <label
            for="classLabelColor"
            class="block text-sm font-medium text-gray-700 m-2"
          >
            ModelClassID
          </label>
          <select
            class="block w-11/12 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
            id="classLabelColor"
            ref={modelIdSelector}
            onChange={() => selectModelClassIdHandler()}
          >
            <Show when={props.getClassLabel().id !== ""}>
              <option
                class="text-gray-500"
                value={props.getClassLabel().modelClassId}
              >
                {props.getClassLabel().modelClassId}*
              </option>
            </Show>
            <Show when={props.getClassLabel().id === ""}>
              <option />
            </Show>
            <For each={modelClassIds}>
              {(id) => (
                <Show
                  when={
                    id !== Number.parseInt(props.getClassLabel().modelClassId)
                  }
                >
                  <option value={id}>{id}</option>
                </Show>
              )}
            </For>
          </select>
          <label
            for="classLabelID"
            class="block text-sm font-medium text-gray-700 m-2"
          >
            ID
            <a
              onClick={() =>
                navigator.clipboard.writeText(props.getClassLabel().id)
              }
              class="cursor-pointer active:animate-ping text-black active:text-green-400 ml-2"
            >
              <CopyIcon />
            </a>
          </label>

          <p class="block w-11/12 rounded-md border border-gray-300 px-3 py-2 sm:text-sm m-2">
            {props.getClassLabel().id}
          </p>
        </div>
        <Show when={createErrorMessage()}>
          <p class="text-red-400 p-3 text-sm">All fields required</p>
        </Show>
        <div class="flex flex-row justify-center my-1">
          <Show when={props.getClassLabel().id === ""}>
            <a
              class="nl-button nl-button--xs m-1 cursor-pointer"
              classList={{
                "opacity-100": allFieldsRequired(),
                "opacity-50 pointer-events-none": !allFieldsRequired(),
              }}
              onClick={() => {
                if (!allFieldsRequired()) {
                  setCreateErrorMessage(true);
                } else {
                  setShowCreateConfirmationBox(true);
                }
              }}
            >
              Create
            </a>
          </Show>
          <Show when={props.getClassLabel().id !== ""}>
            <a
              class="nl-button nl-button--xs m-1 cursor-pointer"
              classList={{
                "opacity-100": changesMade(),
                "opacity-50 pointer-events-none": !changesMade(),
              }}
              onClick={() => {
                setShowUpdateConfirmationBox(true);
              }}
            >
              Save
            </a>
          </Show>
          <a
            class="nl-button nl-button--xs m-1  text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
            onClick={() => {
              props.closeOverview();
            }}
          >
            Cancel
          </a>
          <Show when={showCreateConfirmationBox()}>
            <CreateConfirmationBox
              showDialog={setShowCreateConfirmationBox}
              createFunction={createClassLabelFunction}
              nameToUpdate={nameInput.value}
            ></CreateConfirmationBox>
          </Show>
          <Show when={showUpdateConfirmationBox()}>
            <UpdateConfirmationBox
              showDialog={setShowUpdateConfirmationBox}
              updateFunction={updateClassLabelFunction}
              nameToUpdate={props.getClassLabel().name}
            ></UpdateConfirmationBox>
          </Show>

          <Show when={props.getClassLabel().id !== ""}>
            <a
              class="nl-button nl-button--xs m-1 bg-nl-red-400 hover:bg-nl-red-800 cursor-pointer"
              onClick={() => deleteClassLabel()}
            >
              Delete
            </a>
          </Show>
        </div>
      </div>
      <Show when={deleteClassLabelResponse.error}>
        <p class="block text-sm font-medium text-red-700 m-2">
          Something went wrong, could not delete. ErrorMessage:
          {deleteClassLabelResponse.error}
        </p>
      </Show>
      <Show when={deleteClassLabelResponse.error}>
        <p class="block text-sm font-medium text-red-700 m-2">
          Something went wrong, could not delete. ErrorMessage:
          {deleteClassLabelResponse.error}
        </p>
      </Show>
      <Show when={cantDeleteMessage()}>
        <p class="block text-sm font-medium text-red-700 m-2">
          Can't delete. Classlabel is used in ImageAnnotation.
        </p>
      </Show>
      <Show when={deleteClassLabelResponse.loading}>
        <div class="mx-auto">
          <SpinnerIcon />
        </div>
      </Show>
      <Show when={showDeleteConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteConfirmationBox}
          nameToDelete={props.getClassLabel().name}
          idToDelete={props.getClassLabel().id}
          deleteFunction={deleteClassLabelFunction}
          type={EntityTypes.classLabel}
        ></DeleteConfirmationBox>
      </Show>
    </div>
  );
}
