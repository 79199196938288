import {
  Accessor,
  For,
  Setter,
  Show,
  createEffect,
  createSignal,
} from "solid-js";
import {
  MachineListCompressed,
  ModelsListCompressed,
  SingleTaskType,
  TasksListExtended,
} from "../../../helpers/types";
import { ChevronDoubleRight, CopyIcon } from "../../../components/Icons";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import { _UpdateTask } from "../../../helpers/mutations";
import UpdateConfirmationBox from "../../../components/UpdateConfirmationBox";
import DatasetCard from "./DatasetCard";
import { _emptyTask } from "./TaskOverview";

interface ConfigTaskProps {
  updatableTask: Accessor<SingleTaskType>;
  setUpdatableTask: Setter<SingleTaskType>;
  refetchTasks: (
    info?: unknown,
  ) =>
    | TasksListExtended
    | Promise<TasksListExtended | undefined>
    | null
    | undefined;
  getMachinesList: Accessor<MachineListCompressed | undefined>;
  getModelsList: Accessor<ModelsListCompressed | undefined>;
  showOverview: Setter<boolean>;
  client: GraphQLClientQuery;
}

export default function ConfigTaskOverview(props: ConfigTaskProps) {
  var descriptionInput!: HTMLTextAreaElement;
  var machineSelector!: HTMLSelectElement;
  var modelSelector!: HTMLSelectElement;
  var updateFunction: any;

  //For confirmationsboxes

  const [showUpdateConfirmationBox, setShowUpdateConfirmationBox] =
    createSignal(false);

  //For keeping track of changes
  const [descriptionIsChanged, setDescriptionIsChanged] =
    createSignal<boolean>(false);
  const [machineIsChanged, setMachineIsChanged] = createSignal<boolean>(false);
  const [modelIsChanged, setModelIsChanged] = createSignal<boolean>(false);

  //TODO: Är detta en bra lösning?
  var selectedModel: any;
  const [datasetsUsedByModel, setDatasetsUsedByModel] =
    createSignal(selectedModel);
  const [selectedModelName, setSelectedModelName] = createSignal<string>();

  //Set to false as to not fire on mount
  const [updateQueryInput, setUpdateQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [updatedTaskResponse] = props.client(_UpdateTask, updateQueryInput);

  //Subscribers to changes
  createEffect(() => {
    if (
      !updatedTaskResponse.loading &&
      !updatedTaskResponse.error &&
      updatedTaskResponse()
    ) {
      props.refetchTasks();
      props.setUpdatableTask(_emptyTask);
      setDescriptionIsChanged(false);
      setUpdateQueryInput(false);
      props.showOverview(false);
    }
  });

  createEffect(() => {
    if (props.updatableTask().ModelID !== null) {
      selectedModel = props
        .getModelsList()
        ?.find((model) => model.id === props.updatableTask().ModelID);
      setDatasetsUsedByModel(selectedModel);
      setSelectedModelName(selectedModel?.name);
    }
    if (props.updatableTask().ModelID === null) {
      setDatasetsUsedByModel(undefined);
    }
    descriptionInput.value = "";
    setDescriptionIsChanged(false);
  });

  //Handlers and functions
  const descriptionInputHandler = () => {
    if (
      descriptionInput.value !== props.updatableTask().description.description
    ) {
      setDescriptionIsChanged(true);
    }
    if (
      descriptionInput.value ===
        props.updatableTask().description.description ||
      descriptionInput.value === ""
    ) {
      setDescriptionIsChanged(false);
    }
  };

  const selectMachineHandler = (id: string) => {
    console.log(id);
    if (id === props.updatableTask().MachineID) {
      setMachineIsChanged(false);
    } else {
      setMachineIsChanged(true);
    }
  };

  const selectModelHandler = (id: string) => {
    if (id === props.updatableTask().ModelID) {
      setModelIsChanged(false);
    } else {
      setModelIsChanged(true);
    }

    selectedModel = props.getModelsList()?.find((model) => model.id === id);
    setDatasetsUsedByModel(selectedModel);
    setSelectedModelName(selectedModel?.name);
  };

  const updateTaskMachine = () => {
    const updateTaskId = props.updatableTask().id;
    const input = { MachineID: machineSelector.value };
    setUpdateQueryInput({ updateTaskId: updateTaskId, input });
  };

  const updateTaskModel = () => {
    const updateTaskId = props.updatableTask().id;
    const input = { ModelID: modelSelector.value };
    setUpdateQueryInput({ updateTaskId: updateTaskId, input });
  };

  const updateTaskDescription = () => {
    const updateTaskId = props.updatableTask().id;
    const input = { description: { description: descriptionInput.value } };

    console;
    setUpdateQueryInput({ updateTaskId: updateTaskId, input });
  };

  return (
    <div class="mx-auto my-10 w-10/12 bg-white shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm">
      <div id="singletaskoverview">
        <h2 class="px-4 pb-2 pt-4 text-xl font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 bg-gray-50 bg-opacity-75 ">
          Task - {props.updatableTask().description.description}
        </h2>
      </div>
      <Show when={updatedTaskResponse.error}>
        <p class="block text-sm font-medium text-red-700 mt-10 ml-10">
          *Something went wrong, could not update. Try again or reload page.
        </p>
      </Show>
      <div class="border-b">
        <div class="flex flex-row">
          <div class="w-1/2 p-5">
            <label
              for="taskDescription"
              class="block font-medium text-gray-700 px-5 mt-5 mb-1"
            >
              Description
            </label>
            <textarea
              class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
              classList={{
                "border-2 border-green-400 shadow-sm": descriptionIsChanged(),
              }}
              id="taskDescription"
              ref={descriptionInput}
              placeholder={props.updatableTask().description.description}
              onInput={() => descriptionInputHandler()}
            />
            <Show when={descriptionIsChanged()}>
              <div class="flex flex-row justify-center w-10/12 mx-5 my-2">
                <a
                  class="nl-button nl-button--xs m-1 text-white bg-nl-blue-300 hover:bg-nl-blue-800 cursor-pointer"
                  onClick={(event: MouseEvent) => {
                    if (descriptionIsChanged()) {
                      event.preventDefault();
                      updateFunction = updateTaskDescription;
                      setShowUpdateConfirmationBox(true);
                      window.location.href = "#taskoverview";
                    }
                  }}
                >
                  Save
                </a>
              </div>
            </Show>
          </div>

          <div class="w-1/2 p-5">
            <div class="my-5">
              <h5 class="mx-5 block font-medium text-gray-700">Details</h5>
              <div class="mx-5 mt-3">
                <div class="block text-sm">
                  <p class=" font-medium text-gray-700">ID</p>
                  <div class="flex flex-row items-center">
                    <p class="text-gray-500">{props.updatableTask().id} </p>
                    <a
                      onClick={() =>
                        navigator.clipboard.writeText(props.updatableTask().id)
                      }
                      class="cursor-pointer active:animate-ping active:text-green-400 mx-1"
                    >
                      <CopyIcon />
                    </a>
                  </div>
                </div>
                <div class="block text-sm my-2">
                  <p class=" font-medium text-gray-700">Created</p>
                  <p class="text-gray-500">
                    {new Date(
                      props.updatableTask().createdAt,
                    ).toLocaleDateString("sv-SE")}{" "}
                    |{" "}
                    {new Date(
                      props.updatableTask().createdAt,
                    ).toLocaleTimeString("sv-SE")}{" "}
                  </p>
                </div>
                <div class="block text-sm my-2">
                  <p class=" font-medium text-gray-700">Last updated</p>
                  <p class="text-gray-500">
                    {new Date(
                      props.updatableTask().updatedAt,
                    ).toLocaleDateString("sv-SE")}{" "}
                    |{" "}
                    {new Date(
                      props.updatableTask().updatedAt,
                    ).toLocaleTimeString("sv-SE")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-7 mx-5">
        <h5 class="text-xl font-medium text-gray-700 mx-5 my-3">Setup</h5>
        <div class="flex flex-row">
          <div class="w-1/2 p-5">
            <div class="flex flex-row mb-2">
              <h5 class="block mr-3 text-medium font-medium text-gray-700">
                Current machine
              </h5>
              <ChevronDoubleRight />
            </div>
            <select
              ref={machineSelector}
              class="block w-8/12 appearance-none rounded-md border border-gray-300 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-500 sm:text-sm"
              id="currentMachine"
              onChange={(event: any) => {
                selectMachineHandler(event?.target.value);
              }}
            >
              <Show when={props.updatableTask().MachineID !== null}>
                <option
                  class="text-gray-500"
                  value={props.updatableTask().MachineID}
                >
                  {props.updatableTask().machine.name} - Active
                </option>
              </Show>
              <Show when={props.updatableTask().MachineID === null}>
                <option class="text-gray-500" selected>
                  No current machine registred
                </option>
              </Show>
              <For each={props.getMachinesList()}>
                {(machine) => (
                  <Show when={machine.id !== props.updatableTask().MachineID}>
                    <option value={machine.id}>{machine.name}</option>
                  </Show>
                )}
              </For>
            </select>

            <div class="flex flex-row w-8/12 my-2 justify-center">
              <Show when={machineIsChanged()}>
                <a
                  class="nl-button nl-button--xs m-1 text-white bg-nl-blue-400 hover:bg-nl-blue-800 cursor-pointer"
                  onClick={(event: MouseEvent) => {
                    event.preventDefault();
                    updateFunction = updateTaskMachine;
                    setShowUpdateConfirmationBox(true);
                    window.location.href = "#taskoverview";
                  }}
                >
                  Change machine
                </a>
              </Show>
            </div>
          </div>
          <div class="w-1/2 p-5">
            <div class="flex flex-row mb-2">
              <h5 class="block ml-5 mr-3 text-medium font-medium text-gray-700 self-start">
                Current model
              </h5>
              <ChevronDoubleRight />
            </div>
            <select
              ref={modelSelector}
              class="block w-8/12 ml-5 appearance-none rounded-md border border-gray-300 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-500 sm:text-sm"
              id="currentModel"
              onChange={(event: any) => {
                selectModelHandler(event?.target.value);
              }}
            >
              <Show when={props.updatableTask().ModelID !== null}>
                <option
                  class="text-gray-500"
                  value={props.updatableTask().ModelID}
                >
                  {props.updatableTask().model.name} - Active
                </option>
              </Show>
              <Show when={props.updatableTask().ModelID === null}>
                <option class="text-gray-500" selected>
                  No current model registred
                </option>
              </Show>
              <For each={props.getModelsList()}>
                {(model) => (
                  <Show when={model.id !== props.updatableTask().ModelID}>
                    <option value={model.id}>{model.name}</option>
                  </Show>
                )}
              </For>
            </select>

            <div class="flex flex-row w-8/12 mx-5 my-2 justify-center">
              <Show when={modelIsChanged()}>
                <a
                  class="nl-button nl-button--xs m-1 text-white bg-nl-blue-400 hover:bg-nl-blue-800 cursor-pointer"
                  onClick={(event: MouseEvent) => {
                    updateFunction = updateTaskModel;
                    setShowUpdateConfirmationBox(true);
                    window.location.href = "#taskoverview";
                  }}
                >
                  Change model
                </a>
              </Show>
            </div>
          </div>
        </div>
        <Show when={datasetsUsedByModel() !== undefined}>
          <div class="my-5">
            <div class="flex flex-row">
              <h5 class="block ml-5 mr-3 my-auto text-medium font-medium text-gray-700">
                Datasets used by {selectedModelName()}
              </h5>
              <ChevronDoubleRight />
            </div>
            <div class="grid grid-cols-2">
              <For each={datasetsUsedByModel()?.datasets.items}>
                {(dataset) => (
                  <DatasetCard
                    name={dataset.Dataset.name}
                    id={dataset.Dataset.id}
                    updatedAt={dataset.Dataset.updatedAt}
                    images={dataset.Dataset.images.items.length}
                  ></DatasetCard>
                )}
              </For>
            </div>
            <Show when={datasetsUsedByModel()?.datasets.items.length === 0}>
              <div class="block text-sm my-4 mx-5">
                <p class=" font-medium text-gray-500">
                  No current datasets used by model
                </p>
              </div>
            </Show>
          </div>{" "}
        </Show>
      </div>

      <div class="flex flex-row justify-center p-5">
        <a
          class="nl-button nl-button--xs m-1  text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
          onClick={(event: MouseEvent) => {
            props.showOverview(false);
            window.location.href = "#taskoverview";
          }}
        >
          Cancel
        </a>
      </div>
      <Show when={showUpdateConfirmationBox()}>
        <UpdateConfirmationBox
          showDialog={setShowUpdateConfirmationBox}
          updateFunction={updateFunction}
          nameToUpdate={props.updatableTask().description.description}
        ></UpdateConfirmationBox>
      </Show>
    </div>
  );
}
